import { capitalize } from "lodash";

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d+)}/g, (s, num) => args[num]);

export const formatToLikeQuery = field =>
  field.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

export const formatLabel = label =>
  label.replace(/\w+/g, word => capitalize(word));

export const removeNullBytes = (str = "") =>
  str
    .split("")
    .filter(char => char.codePointAt(0))
    .join("");
