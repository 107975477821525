import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Card } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../../../constants";
import style from "./IntegrationCard.module.scss";
import APIStatus from "./APIStatus";

const IntegrationCard = () => {
  return (
    <Card className={style.integration}>
      <Container>
        <Row className={style.integrationWrapper}>
          <Col>
            <Card.Title className={style.integrationTitle}>
              {M.API_INTEGRATION}
            </Card.Title>
            <Card.Text className={style.integrationText}>
              {M.API_INTEGRATION_TEXT()}
            </Card.Text>
          </Col>
          {process.env.REACT_APP_FEATURE_API_PORTAL_ENABLED === "true" && (
            <Col lg={3} className={style.available}>
              <Container className={style.availableWrapper}>
                <Row className={style.availableTitle}>{M.INTERESTED}</Row>
                <APIStatus />
              </Container>
            </Col>
          )}
        </Row>
      </Container>
    </Card>
  );
};

IntegrationCard.propTypes = {
  modal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickRetry: PropTypes.func,
};

export default IntegrationCard;
