import PropTypes from "prop-types";

const StatusLabel = ({ label, failed, failMessage }) => {
  return (
    <>
      {label}
      {failed && (
        <>
          {" "}
          (<span className="text-danger font-italic">{failMessage}</span>)
        </>
      )}
    </>
  );
};

StatusLabel.propTypes = {
  label: PropTypes.node,
  failed: PropTypes.bool,
  failMessage: PropTypes.string,
};

export default StatusLabel;
