import { useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Button } from "@dpdgroupuk/mydpd-ui";

import styles from "./Modal.module.scss";

const CustomModal = ({ modalContent, confirmButtonText, onConfirm }) => {
  const { header, title, body, icon, enabled } = modalContent;
  const [show, setShow] = useState(!!enabled);

  const onClose = () => {
    setShow(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      centered
      contentClassName={styles.modalContent}
    >
      <Modal.Header className={styles.modalHeader}>{header}</Modal.Header>
      <Modal.Body>
        <div className={styles.modalBody}>
          {icon && <img className={styles.modalIcon} src={icon} />}
          <div className={styles.modalWrapper}>
            <p className={styles.modalTitle}>{title}</p>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button onClick={onClose}>{confirmButtonText || "OK"}</Button>
      </Modal.Footer>
    </Modal>
  );
};

CustomModal.propTypes = {
  modalContent: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    icon: PropTypes.string,
    enabled: PropTypes.string.isRequired,
  }),
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default CustomModal;
