// Fields
export const PRE_ARRANGED = "autoCollectEnabled";
export const PRODUCT = "defaultProduct";
export const SERVICE = "defaultService";
export const NUMBER_OF_ITEMS = "defaultNumItem";
export const WEIGHT = "defaultWeight";
export const CLOSING_TIME = "closingTime";

export const FIELDS = [
  PRE_ARRANGED,
  PRODUCT,
  SERVICE,
  NUMBER_OF_ITEMS,
  WEIGHT,
  CLOSING_TIME,
];

export const INTEGER_FIELDS = [NUMBER_OF_ITEMS, WEIGHT];
