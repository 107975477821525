import { Redirect, Route, Switch } from "react-router";

import {
  CollectionDefaultsPage,
  ContactDetailsPage,
  InvoiceDefaultsPage,
  PrintingSettingsPage,
  ShippingDefaultsPage,
} from "../pages";
import {
  COLLECTION_DEFAULTS,
  CONTACT_DETAILS,
  INVOICE_DEFAULTS,
  PRINTING_SETTINGS,
  SHIPPING_DEFAULTS,
} from "./constants";

export const Routes = () => (
  <Switch>
    <Route exact path={CONTACT_DETAILS} component={ContactDetailsPage} />
    <Route exact path={PRINTING_SETTINGS} component={PrintingSettingsPage} />
    <Route
      exact
      path={COLLECTION_DEFAULTS}
      component={CollectionDefaultsPage}
    />
    <Route exact path={SHIPPING_DEFAULTS} component={ShippingDefaultsPage} />
    <Route exact path={INVOICE_DEFAULTS} component={InvoiceDefaultsPage} />
    <Redirect to={CONTACT_DETAILS} />
  </Switch>
);
