import { isEmpty } from "lodash";

import { M } from "../../../../../constants";
import { UKIMS_NUMBER } from "../../../../../constants/forms/invoiceDefaults";

export const validate = values => {
  const lengthError =
    !isEmpty(values[UKIMS_NUMBER]) && values[UKIMS_NUMBER]?.length !== 32
      ? { [UKIMS_NUMBER]: M.UKIMS_NUMBER_MUST_BE_EQUAL_$(32) }
      : {};

  return { ...lengthError };
};
