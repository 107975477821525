import PropTypes from "prop-types";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import errorIcon from "../../assets/icons/error-icon.svg";
import { M } from "../../constants";
import style from "./ErrorModal.module.scss";

const ErrorModal = ({ showModal, modalBody, onClickCancel, onClickRetry }) => {
  return (
    <Modal
      show={showModal}
      backdrop="static"
      className={style.modal}
      centered
      contentClassName={style.modalContent}
    >
      <Modal.Header className={style.modalHeader}>
        {M.SOMETHINGS_GONE_WRONG}
      </Modal.Header>
      <Modal.Body className={style.modalBody}>
        <img className={style.warningIcon} src={errorIcon} />
        {(modalBody && modalBody()) || M.GENERIC_ERROR_MODAL_BODY()}
      </Modal.Body>
      <Modal.Footer className={style.modalFooter}>
        <Button variant="dark" onClick={onClickCancel}>
          {M.CANCEL}
        </Button>
        <Button onClick={onClickRetry}>{M.RETRY}</Button>
      </Modal.Footer>
    </Modal>
  );
};

ErrorModal.propTypes = {
  showModal: PropTypes.bool,
  modalBody: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickRetry: PropTypes.func,
};

export default ErrorModal;
