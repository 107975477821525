import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import {
  COLLECTION_DETAILS_FORM,
  CONTACT_DETAILS_FORM,
  INVOICE_SETTINGS_FORM,
  SHIPPING_DEFAULTS_FORM,
} from "../constants/forms/formsNames";
import { PrintingSettingsReducer } from "../pages/Account/pages/PrintingSettings/redux";
import { EcommerceReducer } from "../pages/Ecommerce/redux";
import { CmsReducer } from "./cms";
import { plugins } from "./form";
import { ReferenceReducer } from "./reference";
import { SalesforceReducer } from "./salesforce";
import { UserReducer } from "./user";

const combinePlugins = plugins => (state, action) =>
  plugins.reduce((acc, curr) => curr(acc, action), state);

export const rootReducer = combineReducers({
  form: reduxFormReducer.plugin({
    [INVOICE_SETTINGS_FORM]: combinePlugins([plugins.toUppercase]),
    [COLLECTION_DETAILS_FORM]: combinePlugins([plugins.toUppercase]),
    [CONTACT_DETAILS_FORM]: combinePlugins([plugins.toUppercase]),
    [SHIPPING_DEFAULTS_FORM]: combinePlugins([
      plugins.toUppercase,
      plugins.weightToFloatString,
    ]),
  }),
  app: combineAppReducer({
    user: UserReducer,
    cms: CmsReducer,
    reference: ReferenceReducer,
    ecommerce: EcommerceReducer,
    printingSettings: PrintingSettingsReducer,
    salesforce: SalesforceReducer,
  }),
});
