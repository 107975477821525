// Fields
export const TITLE = "title";
export const FORENAME = "forename";
export const SURNAME = "lastname";
export const POSITION = "position";
export const EMAIL = "email";
export const TELEPHONE = "phone";

// Dropdown values
export const TITLE_VALUES = [
  { label: "Mr", value: "MR" },
  { label: "Mrs", value: "MRS" },
  { label: "Miss", value: "MISS" },
  { label: "Ms", value: "MS" },
];

export const FIELDS = [TITLE, FORENAME, SURNAME, POSITION, EMAIL, TELEPHONE];
