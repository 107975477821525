import { fromPairs, includes, map } from "lodash";

import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";

import { A, M } from "../constants";
import {
  ACCOUNT_PAGE,
  API_PAGE,
  E_COMMERCE_PAGE,
  WELCOME_PAGE,
} from "../router";

const {
  REACT_APP_FEATURE_SALESFORCE_SUPPORT_ENABLED,
  REACT_APP_FEATURE_ECOMMERCE_SUPPORT_ENABLED,
} = process.env;

export const getPrimaryMenuActions = () => ({
  0: A.MENU.ON_CLICK_MYDPD,
  1: A.MENU.ON_CLICK_SHIPPING,
  2: A.MENU.ON_CLICK_COLLECTIONS,
  3: A.MENU.ON_CLICK_DELIVERIES,
  4: A.MENU.ON_CLICK_SHOP_RETURNS,
  5: A.MENU.ON_CLICK_ADDRESS_BOOK,
  6: A.MENU.ON_CLICK_DEPOT_FINDER,
});

export const getSecondaryMenu = (urlResolver, appRoles) => {
  const links = [
    {
      href: WELCOME_PAGE,
      name: M.WELCOME,
    },
    {
      href: ACCOUNT_PAGE,
      name: M.ACCOUNT_DETAILS,
    },
  ];

  if (includes(appRoles, APP_ROLES.REPORTS)) {
    links.push({
      href: urlResolver.getUrl("mydpd.myreports"),
      name: M.REPORTS,
    });
  }

  if (REACT_APP_FEATURE_ECOMMERCE_SUPPORT_ENABLED === "true") {
    links.push({
      href: E_COMMERCE_PAGE,
      name: M.E_COMMERCE,
    });
  }

  links.push({
    href: API_PAGE,
    name: M.API,
  });

  return links;
};

export const getWelcomeSecondaryMenuActions = appRoles => {
  const actions = [
    A.WELCOME_MENU.ON_CLICK_WELCOME,
    A.WELCOME_MENU.ON_CLICK_MY_ACCOUNT_DETAILS,
  ];

  if (includes(appRoles, APP_ROLES.REPORTS)) {
    actions.push(A.WELCOME_MENU.ON_CLICK_REPORTS);
  }

  actions.push(A.WELCOME_MENU.ON_CLICK_API);

  return fromPairs(
    actions,
    map(actions, (action, index) => [index, action])
  );
};

export const QUICK_LINKS = {
  HAVE_A_QUERY: "HAVE_A_QUERY",
};

const isSalesforceSupportEnabled = () =>
  REACT_APP_FEATURE_SALESFORCE_SUPPORT_ENABLED === "true";

export const getQuickLinks = (urlResolver, cardActions) => {
  const links = [
    {
      title: "Create New Shipment",
      link: `${urlResolver.getUrl("shipping")}/create`,
      icon: "create-new-shipment",
    },
    {
      title: "Manage Your Shipments",
      link: `${urlResolver.getUrl("shipping")}/review`,
      icon: "manage-your-shipments",
    },
    {
      title: "Delivery Dashboards",
      link: `${urlResolver.getUrl("deliveries")}/dashboard`,
      icon: "delivery-dashboards",
    },
    {
      title: "My Account",
      link: urlResolver.getUrl("mydpd.mypreferences"),
      icon: "my-account",
    },
  ];

  if (isSalesforceSupportEnabled()) {
    links.push({
      title: "Have A Query",
      icon: "have-a-query",
      onLoad: () => cardActions[QUICK_LINKS.HAVE_A_QUERY].onLoad(),
      onClickRetry: () => cardActions[QUICK_LINKS.HAVE_A_QUERY].onClickRetry(),
      onClickCancel: () =>
        cardActions[QUICK_LINKS.HAVE_A_QUERY].onClickCancel(),
      modalBody: cardActions[QUICK_LINKS.HAVE_A_QUERY].modalBody,
    });
  } else {
    links.push({
      title: "Watch Your Parcels",
      link: `${urlResolver.getUrl("deliveries")}/watchlist`,
      icon: "watch-your-parcels",
    });
  }

  return links;
};

export const QUICK_LINKS_ACTIONS = [
  A.QUICK_LINKS.ON_CLICK_CREATE_SHIPMENT,
  A.QUICK_LINKS.ON_CLICK_DELIVERY_DASHBOARD,
  A.QUICK_LINKS.ON_CLICK_MANAGE_YOUR_SHIPMENTS,
  A.QUICK_LINKS.ON_CLICK_MY_ACCOUNT,
  isSalesforceSupportEnabled()
    ? A.QUICK_LINKS.ON_CLICK_HAVE_A_QUERY
    : A.QUICK_LINKS.ON_CLICK_WATCH_YOUR_PARCELS,
];
