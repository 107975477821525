import { AppActions } from "@dpdgroupuk/mydpd-app";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient, userApis } from "../../../../../apis";

export const ContactDetailsActionTypes = createActionTypes("CONTACT_DETAILS", {
  UPDATE_CONTACT_DETAILS: createAsyncActionTypes("UPDATE_CONTACT_DETAILS"),
});

export const updateContactDetails = createAsyncAction(
  body => userApis.updateContactDetails(body),
  ContactDetailsActionTypes.UPDATE_CONTACT_DETAILS
);

export const onSubmit = data => dispatch =>
  dispatch(updateContactDetails(data)).then(() =>
    dispatch(AppActions.getAppUser(apisClient))
  );
