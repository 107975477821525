export const onlyBoolean = value =>
  typeof value === "string" ? value.toLowerCase() === "true" : !!value;

export const integerNormalize = value =>
  value && value.replace(/[^\d]/g, "").replace(/^0/g, "");

export const floatNormalize = value => {
  const numberValue = value
    .replace(/[^0-9.,]/g, "")
    .replace(/,/g, ".")
    .replace(/^0[^.]/g, "0.")
    .replace(/^\./g, "0.");

  const halfFloatValue = numberValue.match(/^-?\d+\./g);
  const fullFloatValue = numberValue.match(/-?\d+\.\d+/g);
  if (halfFloatValue && fullFloatValue) {
    return fullFloatValue[0];
  }
  if (halfFloatValue) {
    return halfFloatValue[0];
  }

  return numberValue;
};

export const notMathChar = value => value && value.replace(/[/+ || /-]/g, "");

export const phoneNumberNormalize = v => {
  let phoneValue = v.replace(/[^+\d]/g, "");

  const plusCharacterIndex = phoneValue.lastIndexOf("+");

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue.slice(0, plusCharacterIndex) +
      phoneValue.slice(plusCharacterIndex + 1);
  }

  return phoneValue;
};
