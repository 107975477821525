import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";

import { Card, Legend } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M } from "../../../../constants";
import { withFormController } from "../../../../hocs";
import { UserModel } from "../../../../models";
import { ReferenceSelectors } from "../../../../store/reference";
import PageContainer from "../../components/PageContainer";
import { AccountDetailsActions } from "../../redux";
import Dimension from "./components/Dimension";
import DomesticShippingDefaults from "./components/DomesticShippingDefaults";
import InternationalShippingDefaults from "./components/InternationalShippingDefaults";
import ScanningDefaults from "./components/ScanningDefaults";
import ShippingDetails from "./components/ShippingDetails";
import {
  ShippingDefaultsActions,
  ShippingDefaultsModel,
  ShippingDefaultsSelectors,
  ShippingDefaultsValidators,
} from "./redux";
import { get } from "lodash";
import { getAvailableDateRange, isWeekend } from "../../../../util/date";
import { UserSelectors } from "../../../../store/user";
import { checkMinDate } from "./redux/actions";

const ShippingDefaults = ({
  user,
  onSubmit,
  products,
  services,
  networks,
  countries,
  currencies,
  filterDate,
  getDatesRange,
}) => (
  <PageContainer onClick={onSubmit}>
    <Legend rightMessage={UserModel.getUserDetailsString(user)} />
    <Card.Stack fluid className={"row-eq-height"}>
      <Col md={12}>
        <ShippingDetails
          countries={countries}
          filterDate={filterDate}
          getDatesRange={getDatesRange}
        />
      </Col>
      <Col md={12}>
        <DomesticShippingDefaults products={products} services={services} />
      </Col>
      <Col md={12}>
        <InternationalShippingDefaults
          networks={networks}
          countries={countries}
          currencies={currencies}
        />
      </Col>
      <Col md={12}>
        <Dimension />
      </Col>
      <Col md={12}>
        <ScanningDefaults />
      </Col>
    </Card.Stack>
  </PageContainer>
);

ShippingDefaults.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  message: PropTypes.string,
  products: PropTypes.string,
  services: PropTypes.string,
  networks: PropTypes.array,
  countries: PropTypes.array,
  currencies: PropTypes.array,
  isWeekendEnable: PropTypes.bool,
  forwardDateOver7Days: PropTypes.bool,
  shippingDefaultsFormValues: PropTypes.object,
};

const mapStateToProps = state => ({
  products: ReferenceSelectors.getProductsKeyValue(state),
  services: ReferenceSelectors.getServicesKeyValue(state),
  networks: ReferenceSelectors.getNetworksKeyValue(state),
  countries: ReferenceSelectors.getCountriesKeyValue(state),
  currencies: ReferenceSelectors.getCurrenciesKeyValue(state),
  shippingDefaultsFormValues:
    ShippingDefaultsSelectors.getShippingDefaultsFormValues(state),
  advancedConfiguration: UserSelectors.getAdvancedConfiguration(state),
});

export default compose(
  connect(mapStateToProps),
  withFormController({
    form: F.FORMS_NAMES.SHIPPING_DEFAULTS_FORM,
    validate: ShippingDefaultsValidators.validate,
    onLoad: AccountDetailsActions.fetchPreferences,
    onSubmit: ShippingDefaultsActions.updateForm,
    mapDataToForm: ({ shippingDefaults }) =>
      ShippingDefaultsModel.mapDataToForm(shippingDefaults),
    entityName: M.SHIPPING_DEFAULTS,
    touchOnChange: true,
  }),
  withHandlers({
    filterDate:
      ({ shippingDefaultsFormValues }) =>
      date =>
        get(
          shippingDefaultsFormValues,
          F.SHIPPING_DEFAULTS.ENABLE_WEEKEND,
          false
        ) || !isWeekend(date),
    getDatesRange:
      ({ shippingDefaultsFormValues, advancedConfiguration }) =>
      () =>
        getAvailableDateRange(
          get(
            shippingDefaultsFormValues,
            F.SHIPPING_DEFAULTS.ENABLE_WEEKEND,
            false
          ),
          get(advancedConfiguration, "forwardDateOver7Days", false)
        ),
  }),
  withTrack({
    loadId: A.SHIPPING_DEFAULTS_IDS.LOAD,
    interfaceId: A.SHIPPING_DEFAULTS_IDS.INTERFACE_ID,
  }),
  withTrackProps({
    onSubmit: A.SHIPPING_DEFAULTS_IDS.CLICK_SAVE,
  }),
  lifecycle({
    componentDidUpdate() {
      const { dispatch } = this.props;
      dispatch(checkMinDate());
    },
  })
)(ShippingDefaults);
