import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { referenceApis } from "../../apis";

export const ReferenceActionTypes = createActionTypes("REFERENCE", {
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
  FETCH_CURRENCIES: createAsyncActionTypes("FETCH_CURRENCIES"),
  FETCH_SERVICES: createAsyncActionTypes("FETCH_SERVICES"),
  FETCH_PRODUCTS: createAsyncActionTypes("FETCH_PRODUCTS"),
  FETCH_NETWORKS: createAsyncActionTypes("FETCH_NETWORKS"),
});

export const fetchCountries = createAsyncAction(
  referenceApis.fetchCountries,
  ReferenceActionTypes.FETCH_COUNTRIES
);

export const fetchCurrencies = createAsyncAction(
  referenceApis.fetchCurrencies,
  ReferenceActionTypes.FETCH_CURRENCIES
);

export const fetchProducts = createAsyncAction(
  referenceApis.fetchProducts,
  ReferenceActionTypes.FETCH_PRODUCTS
);

export const fetchServices = createAsyncAction(
  referenceApis.fetchServices,
  ReferenceActionTypes.FETCH_SERVICES
);

export const fetchNetworks = createAsyncAction(
  referenceApis.fetchNetworks,
  ReferenceActionTypes.FETCH_NETWORKS
);
