import PropTypes from "prop-types";

const ManageYourShipments = ({ fill = "#DC0032", className }) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M32 32H18V18H32V32ZM20 30H30V20H20V30Z" fill="#414042" />
    <path d="M50 32H36V18H50V32ZM38 30H48V20H38V30Z" />
    <path d="M50 50H36V36H50V50ZM38 48H48V38H38V48Z" fill="#414042" />
    <path d="M32 50H18V36H32V50ZM20 48H30V38H20V48Z" fill="#414042" />
  </svg>
);

ManageYourShipments.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default ManageYourShipments;
