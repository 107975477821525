import { useEffect } from "react";

import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withState } from "recompose";
import { compose } from "redux";

import { AuthUtil, withAppLoader } from "@dpdgroupuk/mydpd-app";
import { Card, Main, useOverlay } from "@dpdgroupuk/mydpd-ui";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import { InfoList } from "../../../../components/InfoList";
import { WideBanner } from "../../../../components/WideBanner";
import { M } from "../../../../constants";
import { WELCOME_PAGE } from "../../../../constants/analytics";
import { PAGES } from "../../../../constants/cms";
import { withNotification } from "../../../../hocs";
import { AuthSelectors } from "../../../../store/auth";
import { fetchPageData } from "../../../../store/cms/actions";
import {
  SalesforceActions,
  SalesforceSelectors,
} from "../../../../store/salesforce";
import { QUICK_LINKS } from "../../../../util/menu";
import ImportantNoticeModal from "../ImportantNoticeModal";
import QuickLinks from "../QuickLinks/QuickLinks";
import styles from "./Welcome.module.scss";

const Welcome = ({ user, pageData, showModal, loadFns, isLoading }) => {
  const overlay = useOverlay();

  useEffect(() => {
    if (isLoading) {
      overlay.show();
    } else {
      overlay.hide();
    }
  }, [isLoading, overlay]);

  return (
    <>
      <Main.Body>
        <Card.Stack>
          <WideBanner
            clientName={AuthUtil.getDisplayName(user)}
            banner={pageData?.banner}
          />
          <Container className={styles.cards}>
            <Row className={styles.cardsMedium}>
              <Col md={6}>
                <InfoList
                  title={pageData?.messages?.title}
                  fields={pageData?.messages?.notification}
                />
              </Col>
              <Col md={6}>
                <InfoList
                  title={pageData?.system_updates?.title}
                  fields={pageData?.system_updates?.system_update}
                  linkText={M.CLICK_HERE_FOR_TUTORIAL}
                  linkStyle={styles.linkStyle}
                />
              </Col>
            </Row>
            <Row className={styles.cardsSmall}>
              <QuickLinks loadFns={loadFns} showModal={showModal} />
            </Row>
            {pageData?.important_notice_modal && (
              <ImportantNoticeModal
                modalContent={pageData?.important_notice_modal}
              />
            )}
          </Container>
        </Card.Stack>
      </Main.Body>
    </>
  );
};

Welcome.propTypes = {
  user: PropTypes.object,
  pageData: PropTypes.object,
  showModal: PropTypes.bool,
  loadFns: PropTypes.objectOf(PropTypes.objectOf(PropTypes.func)),
  isLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  user: AuthSelectors.getUser(state),
  showModal: SalesforceSelectors.isModalShown(state),
  isLoading: SalesforceSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch, { withErrorNotification }) => ({
  fetchWelcomeData: withErrorNotification(
    () => dispatch(fetchPageData(PAGES.WELCOME)),
    { entityName: M.PAGE_DATA }
  ),
  loadFns: {
    [QUICK_LINKS.HAVE_A_QUERY]: {
      onLoad: () => dispatch(SalesforceActions.startGenerateToken()),
      onClickRetry: () => {
        dispatch(SalesforceActions.hideModal);
        dispatch(SalesforceActions.startGenerateToken());
      },
      onClickCancel: () => {
        dispatch(SalesforceActions.hideModal);
      },
      modalBody: M.SALESFORCE_SUPPORT_QUERY_GENERATE_TOKEN_FAILURE,
    },
  },
});

export default compose(
  withNotification,
  withState("pageData", "setPageData", {}),
  connect(mapStateToProps, mapDispatchToProps),
  withAppLoader(async ({ fetchWelcomeData, setPageData }) => {
    const res = await fetchWelcomeData();
    setPageData(res.data.fields);
  }),
  withTrack(trackProps(WELCOME_PAGE))
)(Welcome);
