import PropTypes from "prop-types";
import { Row } from "react-bootstrap";

import { Button } from "@dpdgroupuk/mydpd-ui";

import sorryIcon from "../../../../assets/icons/sorry-icon.svg";
import { M } from "../../../../constants";
import style from "./IntegrationCard.module.scss";

export const EcommerceDisabled = () => (
  <>
    <Row>
      <img src={sorryIcon} />
    </Row>
    <Row className={style.availableTitle}>{M.SORRY}</Row>
    <Row className={style.availableText}>{M.ECOMMERCE_DISABLED()}</Row>
  </>
);

const Available = ({ onClick }) => (
  <>
    <Row className={style.availableText}>{M.USE_THE_ECOMMERCE()}</Row>
    <Row>
      <Button
        onClick={() => {
          onClick();
        }}
      >
        {M.GET_STARTED}
      </Button>
    </Row>
  </>
);
Available.propTypes = {
  onClick: PropTypes.func,
};
export const EcommerceAvailable = Available;

export const EcommerceComing = () => (
  <Row className={style.availableText}>{M.ECOMMERCE_COMING_SOON()}</Row>
);
