import moment from "moment";
import {
  DAY_DEFAULT_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from "../constants/dateFormats";

export const getISODateString = date =>
  moment(date, DAY_DEFAULT_DATE_FORMAT).format(ISO_DATE_FORMAT);

export const isSunday = date => date.getDay() === 0;
export const isSaturday = date => date.getDay() === 6;
export const isWeekend = date => isSunday(date) || isSaturday(date);

export const getWeekendNextMonday = (date, enableWeekend) =>
  !enableWeekend && isWeekend(date)
    ? moment(date, DAY_DEFAULT_DATE_FORMAT)
        .add(isSunday(date) ? 1 : 2, "days") // Next Monday
        .format(DAY_DEFAULT_DATE_FORMAT)
    : moment(date, DAY_DEFAULT_DATE_FORMAT).format(DAY_DEFAULT_DATE_FORMAT);

export const getMinShipmentDate = enableWeekend =>
  getWeekendNextMonday(moment().toDate(), enableWeekend);

export const getAvailableDateRange = (enableWeekend, forwardDateOver7Days) => {
  const minDate = moment(
    getMinShipmentDate(enableWeekend),
    DAY_DEFAULT_DATE_FORMAT
  );
  const currentDate = new Date();
  const count = forwardDateOver7Days
    ? 30
    : isWeekend(currentDate) && !enableWeekend
    ? 5
    : 7;

  return {
    minDate: minDate.toDate(),
    maxDate: minDate.add(count, "d").toDate(),
  };
};
