import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field } from "redux-form";

import { Banner, Card, FormControl, Legend } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, M } from "../../../../constants";
import * as F from "../../../../constants/forms/contactDetails";
import { CONTACT_DETAILS_FORM } from "../../../../constants/forms/formsNames";
import { withFormController } from "../../../../hocs";
import { UserModel } from "../../../../models";
import { UserActions } from "../../../../store/user";
import { phoneNumberNormalize } from "../../../../util/normalize";
import PageContainer from "../../components/PageContainer";
import {
  ContactDetailsActions,
  ContactDetailsModel,
  ContactDetailsValidators,
} from "./redux";

const ContactDetails = ({ user, onInputChange, onSubmit, emailDisabled }) => (
  <PageContainer onClick={onSubmit}>
    <Legend
      leftMessage={M.COMPLETE_REQUIRED_FIELDS}
      rightMessage={UserModel.getUserDetailsString(user)}
    />
    <Banner.Container />
    <Card.Stack>
      <Col sm={12}>
        <Card>
          <Card.Header>{M.CONTACT_DETAILS}</Card.Header>
          <Container className="p-0">
            <Row>
              <Col sm={2}>
                <Field
                  component={FormControl.Dropdown}
                  values={F.TITLE_VALUES}
                  name={F.TITLE}
                  label={M.TITLE}
                  maxLength={50}
                  textTransform={M.CAPITALIZE}
                  helperText={M.MAX_$_CHARACTERS(50)}
                  onChange={() => {
                    onInputChange(F.TITLE);
                  }}
                  required
                />
              </Col>
              <Col sm={3}>
                <Field
                  component={FormControl.Input}
                  label={M.FORENAME}
                  name={F.FORENAME}
                  maxLength={20}
                  helperText={M.MAX_$_CHARACTERS(20)}
                  required
                />
              </Col>
              <Col sm={3}>
                <Field
                  component={FormControl.Input}
                  label={M.SURNAME}
                  name={F.SURNAME}
                  maxLength={20}
                  helperText={M.MAX_$_CHARACTERS(20)}
                  required
                />
              </Col>
              <Col sm={4}>
                <Field
                  component={FormControl.Input}
                  label={M.POSITION}
                  name={F.POSITION}
                  maxLength={50}
                  helperText={M.MAX_$_CHARACTERS(50)}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <Field
                  component={FormControl.Input}
                  label={M.EMAIL}
                  name={F.EMAIL}
                  maxLength={50}
                  helperText={M.MAX_$_CHARACTERS(50)}
                  disabled={emailDisabled}
                  required
                />
              </Col>
              <Col sm={3}>
                <Field
                  component={FormControl.Input}
                  label={M.TELEPHONE}
                  name={F.TELEPHONE}
                  maxLength={15}
                  normalize={phoneNumberNormalize}
                  helperText={M.MAX_$_CHARACTERS(15)}
                  required
                />
              </Col>
            </Row>
          </Container>
        </Card>
      </Col>
    </Card.Stack>
  </PageContainer>
);

ContactDetails.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
  emailDisabled: PropTypes.bool,
};

export default compose(
  withFormController({
    form: CONTACT_DETAILS_FORM,
    validate: ContactDetailsValidators.validate,
    onLoad: UserActions.fetchUser,
    onSubmit: ContactDetailsActions.onSubmit,
    mapDataToForm: ContactDetailsModel.mapDataToForm,
    entityName: M.CONTACT_DETAILS,
  }),
  withTrack({
    loadId: A.CONTACT_DETAILS_IDS.LOAD,
    interfaceId: A.CONTACT_DETAILS_IDS.INTERFACE_ID,
  }),
  withTrackProps({
    onSubmit: A.CONTACT_DETAILS_IDS.CLICK_SAVE,
    onInputChange: type => {
      switch (type) {
        case F.TITLE:
          return A.CONTACT_DETAILS_IDS.CLICK_DROPDOWN_LIST_TITLE;
      }
    },
  })
)(ContactDetails);
