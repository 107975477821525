import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { compose } from "recompose";

import { Card, Legend } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M } from "../../../../constants";
import { withFormController } from "../../../../hocs";
import { UserModel } from "../../../../models";
import PageContainer from "../../components/PageContainer";
import { AccountDetailsActions } from "../../redux";
import InvoiceDetails from "./components/InvoiceDetails";
import SenderInvoiceDetails from "./components/SenderInvoiceDetails";
import { invoiceDefaultsActions, invoiceDefaultsModel } from "./redux";

const InvoiceDefaults = ({ user, onInputChange, onSubmit }) => (
  <PageContainer onClick={onSubmit}>
    <Legend rightMessage={UserModel.getUserDetailsString(user)} />
    <Card.Stack fluid>
      <Col md={12}>
        <InvoiceDetails onInputChange={onInputChange} />
      </Col>
      <Col md={12}>
        <SenderInvoiceDetails />
      </Col>
    </Card.Stack>
  </PageContainer>
);

InvoiceDefaults.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
};
export default compose(
  withFormController({
    form: F.FORMS_NAMES.INVOICE_SETTINGS_FORM,
    onLoad: AccountDetailsActions.fetchPreferences,
    onSubmit: invoiceDefaultsActions.submitFormWithValidation,
    mapDataToForm: ({ prefsInvoiceSettings }) =>
      invoiceDefaultsModel.mapDataToForm(prefsInvoiceSettings),
    entityName: M.INVOICE_DEFAULTS,
  }),
  withTrack({
    loadId: A.INVOICE_SETTINGS_IDS.LOAD,
    interfaceId: A.INVOICE_SETTINGS_IDS.INTERFACE_ID,
  }),
  withTrackProps({
    onSubmit: A.INVOICE_SETTINGS_IDS.CLICK_SAVE,
    onInputChange: type => {
      switch (type) {
        case F.INVOICE_DEFAULTS.INVOICE_SELECTION:
          return A.INVOICE_SETTINGS_IDS.CLICK_DROPDOWN_LIST_INVOICE_SELECTION;
        case F.INVOICE_DEFAULTS.INVOICE_TYPE:
          return A.INVOICE_SETTINGS_IDS.CLICK_DROPDOWN_LIST_INVOICE_TYPE;
      }
    },
  })
)(InvoiceDefaults);
