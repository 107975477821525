import { change, initialize } from "redux-form";

import { PRINTER_USB_VENDOR_ID } from "@dpdgroupuk/mydpd-enums";
import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { localApis, userApis } from "../../../../../apis";
import { F } from "../../../../../constants";
import { mapUsbDeviceToFormFields, prepareUpdateData } from "./model";
import {
  getPrintingSettingsValues,
  getThermalPrinterName,
  getThermalPrinterType,
} from "./selectors";

export const ACTIONS = createActionTypes("PRINTING_SETTINGS", {
  UPDATE_PRINTING_SETTINGS: createAsyncActionTypes("UPDATE_PRINTING_SETTINGS"),
  FETCH_PRINTERS: createAsyncActionTypes("FETCH_PRINTERS"),
  TEST_PRINTER: createAsyncActionTypes("TEST_PRINTER"),
  CONFIGURE_PRINTER: createAsyncActionTypes("CONFIGURE_PRINTER"),
  SAVE_WORKSTATION_CONFIG: createAsyncActionTypes("SAVE_WORKSTATION_CONFIG"),
  CONFIGURE_WEB_USB_PRINTER: createAsyncActionTypes(
    "CONFIGURE_WEB_USB_PRINTER"
  ),
  RESET: "RESET",
});

export const onChangeMissingAssetNumberField = isMissingAssetNumber =>
  change(
    F.FORMS_NAMES.PRINTING_SETTING_FORM,
    F.PRINTING_SETTINGS.THERMAL_PRINTER_ASSET_NUMBER,
    isMissingAssetNumber ? F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER_VALUE : ""
  );

export const onConfigureUsbWebPrinter = createAsyncAction(
  () => async (dispatch, getState) => {
    const device = await navigator.usb.requestDevice({
      filters: Object.values(PRINTER_USB_VENDOR_ID).map(vendorId => ({
        vendorId,
      })),
    });

    const formState = {
      ...getPrintingSettingsValues(getState()),
      ...mapUsbDeviceToFormFields(device),
    };
    dispatch(initialize(F.FORMS_NAMES.PRINTING_SETTING_FORM, formState, true));

    return device;
  },
  ACTIONS.CONFIGURE_WEB_USB_PRINTER
);

export const fetchPrinters = createAsyncAction(
  () => localApis.printer.getPrinters(),
  ACTIONS.FETCH_PRINTERS
);

export const configurePrinter = createAsyncAction(
  () => localApis.drivers.configureUsbPrinter().then(({ data }) => data),
  ACTIONS.CONFIGURE_PRINTER
);

export const testPrinter = createAsyncAction(
  () => (dispatch, getState) => {
    const state = getState();
    const printerName = getThermalPrinterName(state);
    const printerType = getThermalPrinterType(state);

    return localApis.printer.printTestLabel(printerName, printerType);
  },
  ACTIONS.TEST_PRINTER
);

export const updateForm = createAsyncAction(
  body => userApis.updatePrintingSettings(prepareUpdateData(body)),
  ACTIONS.UPDATE_PRINTING_SETTINGS
);

export const resetPrintersState = () => createAction(ACTIONS.RESET);

export const saveWorkstationConfig = createAsyncAction(
  config => localApis.settings.saveWorkstationConfig(config),
  ACTIONS.SAVE_WORKSTATION_CONFIG
);
