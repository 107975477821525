import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { cmsApis } from "../../apis";

const ACTION_NAMESPACE = "cms";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_PAGE_DATA: createAsyncActionTypes("FETCH_PAGE_DATA"),
});

export const fetchPageData = createAsyncAction(
  ({ name }) =>
    async () =>
      cmsApis.fetchPageData(name),
  ACTIONS.FETCH_PAGE_DATA
);
