import PropTypes from "prop-types";

const WatchYourParcels = ({ fill = "#DC0032", className }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M30.9706 42.8847H11.1176V11.1161H42.8823V26.2059C42.8823 26.6442 42.5274 27 42.0882 27C41.6491 27 41.2941 26.6442 41.2941 26.2059V12.7043H12.7059V41.2965H30.9706C31.4097 41.2965 31.7647 41.6515 31.7647 42.0906C31.7647 42.5298 31.4097 42.8847 30.9706 42.8847Z" />
    <path
      d="M31.7647 23.8235H22.2353V15.0882C22.2353 14.6499 22.5903 14.2941 23.0294 14.2941C23.4686 14.2941 23.8235 14.6499 23.8235 15.0882V22.2353H30.1765V15.0882C30.1765 14.6499 30.5314 14.2941 30.9706 14.2941C31.4097 14.2941 31.7647 14.6499 31.7647 15.0882V23.8235Z"
      fill="#414042"
    />
    <path
      d="M38.9117 46.0588C38.737 46.0588 38.5631 46.0016 38.4186 45.8865C37.5022 45.1607 32.8423 41.3267 31.0881 37.1869C30.5568 35.96 30.2575 34.7442 30.1995 33.5737C30.1852 33.4681 30.1765 33.3069 30.1765 33.1441C30.1765 30.6323 32.3142 28.5882 34.9412 28.5882C36.5969 28.5882 38.0573 29.399 38.9117 30.6283C39.767 29.399 41.2274 28.5882 42.8823 28.5882C45.5093 28.5882 47.647 30.6323 47.647 33.1441C47.647 33.3045 47.6383 33.4625 47.6216 33.6198C47.5668 34.7442 47.2683 35.9576 46.7386 37.1838C46.7378 37.1838 46.7378 37.1846 46.7378 37.1846C44.9844 41.3235 40.3229 45.1599 39.4065 45.8865C39.2612 46.0016 39.0865 46.0588 38.9117 46.0588ZM32.5699 36.6128C33.742 39.3374 36.6064 42.3249 38.9117 44.2411C41.2171 42.3233 44.0823 39.335 45.2536 36.612C45.2568 36.6033 45.2607 36.5953 45.2639 36.5882L45.2647 36.5874C45.7277 35.5248 45.9881 34.4846 46.0382 33.4943C46.0533 33.3482 46.0588 33.2473 46.0588 33.1441C46.0588 31.5082 44.6342 30.1765 42.8823 30.1765C41.1305 30.1765 39.7059 31.5082 39.7059 33.1441C39.7059 33.5832 39.3509 33.9382 38.9117 33.9382C38.4726 33.9382 38.1176 33.5832 38.1176 33.1441C38.1176 31.5082 36.6938 30.1765 34.9412 30.1765C33.1885 30.1765 31.7647 31.5082 31.7647 33.1441C31.7647 33.2449 31.7702 33.345 31.7814 33.4435C31.8346 34.4845 32.0958 35.5248 32.5588 36.5874C32.5628 36.5961 32.5667 36.6048 32.5699 36.6128Z"
      fill="#414042"
    />
  </svg>
);

WatchYourParcels.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default WatchYourParcels;
