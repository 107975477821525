export const API_ENDPOINTS = {
  AUTH: "/auth",
  CMS: "/cms",
  REFERENCE: "/reference",
  USER: "/ums/user",
  ECOMMERCE: "/ecommerce",
  SALESFORCE: "/salesforce",
};

export const NODE_ENV = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
};

export const DPD_LABEL_PRINTER = "DPD_Label_Printer";
