import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../apis";

export const UserActionTypes = createActionTypes("USER", {
  FETCH_USER: createAsyncActionTypes("FETCH_USER"),
  FETCH_ADVANCED_CONFIGURATIONS: createAsyncActionTypes(
    "FETCH_ADVANCED_CONFIGURATIONS"
  ),
});

export const fetchUser = createAsyncAction(
  userApis.fetchUser,
  UserActionTypes.FETCH_USER
);

export const fetchUserAdvancedConfiguration = createAsyncAction(
  userApis.fetchAdvancedConfiguration,
  UserActionTypes.FETCH_ADVANCED_CONFIGURATIONS
);
