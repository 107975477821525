import CreateNewShipment from "./CreateNewShipment";
import DeliveryDashboards from "./DeliveryDashboards";
import HaveAQuery from "./HaveAQuery";
import ManageYourShipments from "./ManageYourShipments";
import MyAccount from "./MyAccount";
import WatchYourParcels from "./WatchYourParcels";

export default {
  "create-new-shipment": CreateNewShipment,
  "manage-your-shipments": ManageYourShipments,
  "delivery-dashboards": DeliveryDashboards,
  "my-account": MyAccount,
  "watch-your-parcels": WatchYourParcels,
  "have-a-query": HaveAQuery,
};
