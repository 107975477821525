import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { LocalServicePropType } from "@dpdgroupuk/mydpd-app";
import { Button, Form, FormControl } from "@dpdgroupuk/mydpd-ui";

import { F, M } from "../../../../../../constants";

const ThermalServiceDetails = ({
  printers,
  localService,
  isTestPrinterAvailable,
  isConfigureAvailable,
  isAssetNumberDisabled,
  onInputChange,
  onTestPrinter,
  onConfigurePrinter,
  onChangeMissingAssetNumberField,
}) => (
  <>
    <Row>
      <Col sm={6} md={4} lg={4}>
        <Field
          name={F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE}
          component={FormControl.Dropdown}
          label={M.THERMAL_PRINTER_TYPE}
          textTransform={M.UPPERCASE}
          onChange={() => {
            onInputChange(F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE);
          }}
          values={F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPES}
          maxLength={50}
          disabled={!localService.state.isRunning}
        />
      </Col>

      <Col sm={6} md={4} lg={4}>
        <Field
          name={F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME}
          component={FormControl.Dropdown}
          label={M.THERMAL_PRINTER_NAME}
          textTransform={M.UPPERCASE}
          onChange={() => {
            onInputChange(F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME);
          }}
          required
          values={printers}
          maxLength={50}
          disabled={!localService.state.isRunning}
        />
      </Col>
      <Col sm={6} md={4} lg={4}>
        <Button.Toolbar>
          {isTestPrinterAvailable && (
            <Button
              disabled={!localService.state.isRunning}
              variant="dark"
              size="sm"
              className="mb-4"
              onClick={onTestPrinter}
            >
              {M.TEST_PRINTER}
            </Button>
          )}
          {isConfigureAvailable && (
            <Button
              disabled={!localService.state.isRunning}
              variant="dark"
              size="sm"
              className="mb-4"
              onClick={onConfigurePrinter}
            >
              {M.CONFIGURE_USB_PRINTER}
            </Button>
          )}
        </Button.Toolbar>
      </Col>
    </Row>
    <Row>
      <Col sm={6} md={4} lg={4}>
        <Field
          name={F.PRINTING_SETTINGS.THERMAL_PRINTER_ASSET_NUMBER}
          component={FormControl.Input}
          label={M.THERMAL_PRINTER_ASSET_NUMBER}
          required={!isAssetNumberDisabled}
          maxLength={50}
          disabled={isAssetNumberDisabled}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name={F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER}
          id={F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER}
          component={Form.Check}
          label={M.IF_UNABLE_READ_ASSET_NUMBER}
          type="checkbox"
          onChange={(e, isMissingAssetNumber) =>
            onChangeMissingAssetNumberField(isMissingAssetNumber)
          }
          disabled={!localService.state.isRunning}
        />
      </Col>
    </Row>
  </>
);

ThermalServiceDetails.propTypes = {
  localService: LocalServicePropType,
  printers: PropTypes.array,
  printerDriverType: PropTypes.number,
  isTestPrinterAvailable: PropTypes.bool,
  isConfigureAvailable: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  isAssetNumberDisabled: PropTypes.bool,
  onTestPrinter: PropTypes.func,
  onConfigurePrinter: PropTypes.func,
  onChangeMissingAssetNumberField: PropTypes.func,
};

export default ThermalServiceDetails;
