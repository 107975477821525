import PropTypes from "prop-types";

const DeliveryDashboards = ({ fill = "#DC0032", className }) => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M52.5735 43.7137C52.1271 43.7137 51.7655 43.3521 51.7647 42.9057L51.7582 36.5524C51.7582 36.3898 51.704 36.2304 51.6054 36.1026L45.6646 28.4641C45.2011 27.8696 44.5144 27.5 43.8706 27.5H21.8002C21.4039 27.5 21.0294 27.8931 21.0294 28.3088V39.6324C21.0294 40.0796 20.6679 40.4412 20.2206 40.4412C19.7733 40.4412 19.4118 40.0796 19.4118 39.6324V28.3088C19.4118 26.9937 20.5061 25.8824 21.8002 25.8824H43.8706C45.0191 25.8824 46.1676 26.476 46.9409 27.4709L52.8817 35.1086C53.2012 35.5187 53.3759 36.0307 53.3759 36.5507L53.3824 42.904C53.3832 43.3513 53.0208 43.7129 52.5735 43.7137Z"
      fill="#414042"
    />
    <path
      d="M49.6529 37.7438C49.6173 37.7438 49.5817 37.7413 49.5453 37.7365L47.2175 37.4259C44.1626 37.0182 41.7693 34.7026 41.2629 31.6622L40.4711 26.9112C40.3975 26.4704 40.6951 26.0538 41.136 25.9802C41.5784 25.9082 41.9941 26.2035 42.0661 26.6451L42.8579 31.3969C43.2462 33.7312 45.0846 35.5098 47.4302 35.8228L49.758 36.1334C50.2012 36.1924 50.5126 36.5993 50.4528 37.0417C50.3994 37.4485 50.0524 37.7438 49.6529 37.7438Z"
      fill="#414042"
    />
    <path
      d="M26.3038 46.9118C24.1038 46.9118 22.3138 45.1218 22.3138 42.9218C22.3138 40.7218 24.1038 38.9319 26.3038 38.9319C28.5038 38.9319 30.2937 40.7218 30.2937 42.9218C30.2937 45.1218 28.5038 46.9118 26.3038 46.9118ZM26.3038 40.5504C24.9959 40.5504 23.9315 41.614 23.9315 42.9227C23.9315 44.2313 24.9959 45.2941 26.3038 45.2941C27.6116 45.2941 28.676 44.2305 28.676 42.9218C28.676 41.6132 27.6116 40.5504 26.3038 40.5504Z"
      fill="#414042"
    />
    <path
      d="M42.9291 43.674H29.4865C29.0392 43.674 28.6777 43.3125 28.6777 42.8652C28.6777 42.4179 29.0392 42.0564 29.4865 42.0564H42.9291C43.3764 42.0564 43.7379 42.4179 43.7379 42.8652C43.7379 43.3125 43.3764 43.674 42.9291 43.674Z"
      fill="#414042"
    />
    <path
      d="M46.1094 46.8551C43.9094 46.8551 42.1195 45.0652 42.1195 42.8652C42.1195 40.6652 43.9094 38.8753 46.1094 38.8753C48.3094 38.8753 50.0993 40.6652 50.0993 42.8652C50.0993 45.0652 48.3094 46.8551 46.1094 46.8551ZM46.1094 40.4929C44.8015 40.4929 43.7371 41.5565 43.7371 42.8652C43.7371 44.1739 44.8007 45.2375 46.1094 45.2375C47.4181 45.2375 48.4817 44.1739 48.4817 42.8652C48.4817 41.5565 47.4181 40.4929 46.1094 40.4929Z"
      fill="#414042"
    />
    <path d="M37.4396 32.4071L34.9339 33.9099C34.9064 33.9261 34.87 33.935 34.8336 33.935C34.7932 33.935 34.7543 33.9253 34.7252 33.9067L34.6476 33.8582L34.3928 33.7004C34.3362 33.6657 34.2917 33.5864 34.2917 33.5193V33.2556V33.1246C34.2917 33.0574 34.337 32.9628 34.3952 32.9296L34.4462 32.8989L37.0085 31.3621L33.203 29.2527C33.114 29.2034 32.9685 29.2034 32.8795 29.2519L28.9551 31.3824L33.5055 33.9868C33.5646 34.0191 33.6099 34.096 33.6099 34.1647V34.1825V34.4308V35.0075L33.6082 37.9225C33.6082 37.9905 33.5621 38.0689 33.5023 38.1004L33.1593 38.2905C33.1593 38.2905 33.1553 38.2913 33.1537 38.2913C33.1262 38.3051 33.0922 38.314 33.0566 38.314H33.0501C33.0089 38.3148 32.9709 38.3067 32.941 38.2905L32.725 38.1708L32.5972 38.0996C32.539 38.0689 32.4929 37.9904 32.4929 37.9217L32.4921 35.0067H32.4912V34.7576C32.488 34.722 32.4621 34.6791 32.4338 34.6629L28.6032 32.4476L28.6016 36.7804C28.6016 36.8824 28.6736 37.0077 28.761 37.0587L32.89 39.4771C32.9774 39.5288 33.1213 39.528 33.2087 39.4763L37.2827 37.0554C37.3701 37.0037 37.4412 36.8775 37.4412 36.7764L37.4396 32.4071Z" />
    <path d="M15.785 21.4872C15.6491 21.4872 15.5116 21.4532 15.3854 21.3812C14.998 21.1604 14.8621 20.6662 15.0829 20.278L18.8909 13.5882C19.1117 13.2 19.6059 13.0641 19.9941 13.2849C20.3815 13.5057 20.5174 13.9999 20.2966 14.3882L16.4887 21.0779C16.3399 21.34 16.0665 21.4872 15.785 21.4872Z" />
    <path d="M15.7858 21.4484C15.3385 21.4484 14.977 21.086 14.977 20.6396V12.9412C14.977 12.4947 15.3385 12.1324 15.7858 12.1324C16.2331 12.1324 16.5946 12.4947 16.5946 12.9412V20.6396C16.5946 21.086 16.2331 21.4484 15.7858 21.4484Z" />
    <path
      d="M15.7502 33.1618C16.1376 33.1618 16.5283 33.1448 16.9117 33.1092C17.3525 33.0696 17.7496 33.3963 17.7909 33.8412C17.8313 34.286 17.5037 34.6791 17.0589 34.7204C16.6351 34.7592 16.2015 34.7786 15.7721 34.7794C15.768 34.7794 15.7575 34.7794 15.751 34.7794C7.93537 34.7438 1.61765 28.3978 1.61765 20.625C1.61765 12.8207 7.96691 6.4706 15.7721 6.4706C23.5772 6.4706 29.9265 12.8207 29.9265 20.625C29.9265 21.6377 29.8189 22.6479 29.607 23.6274C29.5124 24.0649 29.0829 24.3407 28.6453 24.2469C28.2093 24.1523 27.9319 23.722 28.0257 23.2852C28.2134 22.4182 28.3088 21.5236 28.3088 20.625C28.3088 13.712 22.6851 8.08824 15.7721 8.08824C8.85904 8.08824 3.23529 13.712 3.23529 20.625C3.23529 27.5162 8.84044 33.1407 15.7316 33.1618H15.7502Z"
      fill="#414042"
    />
    <path d="M15.8343 11.2677C16.2042 11.2677 16.504 10.9679 16.504 10.598C16.504 10.2282 16.2042 9.92831 15.8343 9.92831C15.4645 9.92831 15.1646 10.2282 15.1646 10.598C15.1646 10.9679 15.4645 11.2677 15.8343 11.2677Z" />
    <path
      d="M25.8759 21.2947C26.2457 21.2947 26.5456 20.9949 26.5456 20.625C26.5456 20.2551 26.2457 19.9553 25.8759 19.9553C25.506 19.9553 25.2062 20.2551 25.2062 20.625C25.2062 20.9949 25.506 21.2947 25.8759 21.2947Z"
      fill="#414042"
    />
    <path d="M20.8765 12.6063C21.2464 12.6063 21.5462 12.3065 21.5462 11.9366C21.5462 11.5667 21.2464 11.2669 20.8765 11.2669C20.5067 11.2669 20.2068 11.5667 20.2068 11.9366C20.2068 12.3065 20.5067 12.6063 20.8765 12.6063Z" />
    <path
      d="M24.5373 16.2671C24.9071 16.2671 25.207 15.9672 25.207 15.5973C25.207 15.2275 24.9071 14.9276 24.5373 14.9276C24.1674 14.9276 23.8676 15.2275 23.8676 15.5973C23.8676 15.9672 24.1674 16.2671 24.5373 16.2671Z"
      fill="#414042"
    />
    <path
      d="M10.7929 30.1157C11.1628 30.1157 11.4626 29.8159 11.4626 29.446C11.4626 29.0762 11.1628 28.7763 10.7929 28.7763C10.4231 28.7763 10.1232 29.0762 10.1232 29.446C10.1232 29.8159 10.4231 30.1157 10.7929 30.1157Z"
      fill="#414042"
    />
    <path
      d="M7.00684 26.3709C7.37671 26.3709 7.67654 26.071 7.67654 25.7012C7.67654 25.3313 7.37671 25.0315 7.00684 25.0315C6.63697 25.0315 6.33713 25.3313 6.33713 25.7012C6.33713 26.071 6.63697 26.3709 7.00684 26.3709Z"
      fill="#414042"
    />
    <path
      d="M5.66824 21.3481C6.0381 21.3481 6.33794 21.0483 6.33794 20.6784C6.33794 20.3085 6.0381 20.0087 5.66824 20.0087C5.29837 20.0087 4.99853 20.3085 4.99853 20.6784C4.99853 21.0483 5.29837 21.3481 5.66824 21.3481Z"
      fill="#414042"
    />
    <path
      d="M7.00684 16.2671C7.37671 16.2671 7.67654 15.9672 7.67654 15.5973C7.67654 15.2275 7.37671 14.9276 7.00684 14.9276C6.63697 14.9276 6.33713 15.2275 6.33713 15.5973C6.33713 15.9672 6.63697 16.2671 7.00684 16.2671Z"
      fill="#414042"
    />
    <path
      d="M10.7929 12.6063C11.1628 12.6063 11.4626 12.3065 11.4626 11.9366C11.4626 11.5667 11.1628 11.2669 10.7929 11.2669C10.4231 11.2669 10.1232 11.5667 10.1232 11.9366C10.1232 12.3065 10.4231 12.6063 10.7929 12.6063Z"
      fill="#414042"
    />
    <path
      d="M23.7972 10.7371C23.6524 10.7371 23.5076 10.6991 23.3758 10.6182C22.9949 10.3845 22.8751 9.88625 23.1089 9.5061L24.3221 7.52852C24.5559 7.14676 25.0533 7.02867 25.4343 7.26161C25.8152 7.49536 25.9349 7.9936 25.7012 8.37375L24.4879 10.3513C24.3351 10.6004 24.0682 10.7371 23.7972 10.7371Z"
      fill="#414042"
    />
    <path
      d="M26.1412 9.46971C25.994 9.46971 25.8451 9.43008 25.7117 9.34596L23.4494 7.92486C23.0709 7.68706 22.9576 7.18802 23.1954 6.80949C23.4324 6.43177 23.9331 6.31692 24.3108 6.55552L26.5731 7.97662C26.9516 8.21442 27.0648 8.71346 26.8271 9.09199C26.6734 9.33625 26.4105 9.46971 26.1412 9.46971Z"
      fill="#414042"
    />
    <path
      d="M7.71779 10.7582C7.44603 10.7582 7.18074 10.6215 7.02706 10.3724L5.80007 8.37375C5.56632 7.9928 5.68603 7.49537 6.06699 7.26162C6.44713 7.02787 6.94537 7.14677 7.17993 7.52853L8.40691 9.52714C8.64066 9.90809 8.52176 10.4055 8.14081 10.6393C8.00816 10.7202 7.86176 10.7582 7.71779 10.7582Z"
      fill="#414042"
    />
    <path
      d="M5.36007 9.4697C5.09073 9.4697 4.82787 9.33544 4.67419 9.09117C4.4364 8.71264 4.55044 8.2136 4.92816 7.97581L7.19044 6.5547C7.56735 6.31529 8.06882 6.43095 8.30581 6.80867C8.5436 7.1872 8.42956 7.68625 8.05184 7.92404L5.78956 9.34514C5.6561 9.43007 5.50647 9.4697 5.36007 9.4697Z"
      fill="#414042"
    />
    <path
      d="M15.7502 8.08824C15.3029 8.08824 14.9414 7.72588 14.9414 7.27941V3.3186C14.9414 2.87213 15.3029 2.50978 15.7502 2.50978C16.1975 2.50978 16.559 2.87213 16.559 3.3186V7.27941C16.559 7.72588 16.1975 8.08824 15.7502 8.08824Z"
      fill="#414042"
    />
    <path
      d="M19.0899 4.12743H12.4114C11.9641 4.12743 11.6026 3.76508 11.6026 3.3186C11.6026 2.87213 11.9641 2.50978 12.4114 2.50978H19.0899C19.5371 2.50978 19.8987 2.87213 19.8987 3.3186C19.8987 3.76508 19.5371 4.12743 19.0899 4.12743Z"
      fill="#414042"
    />
    <path
      d="M15.8133 31.443C16.1823 31.443 16.4814 31.1439 16.4814 30.7749C16.4814 30.406 16.1823 30.1068 15.8133 30.1068C15.4443 30.1068 15.1452 30.406 15.1452 30.7749C15.1452 31.1439 15.4443 31.443 15.8133 31.443Z"
      fill="#414042"
    />
  </svg>
);

DeliveryDashboards.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default DeliveryDashboards;
