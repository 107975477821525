import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { AuthUtil, withAppLoader } from "@dpdgroupuk/mydpd-app";
import { Card, Main } from "@dpdgroupuk/mydpd-ui";
import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import icon from "../../assets/icons/ecommerce.svg";
import { WideBanner } from "../../components/WideBanner";
import { M } from "../../constants";
import { ECOMMERCE_IDS } from "../../constants/analytics";
import { withNotification } from "../../hocs";
import { AuthSelectors } from "../../store/auth";
import { IntegrationCard } from "./components/IntegrationCard";
import { EcommerceActions, EcommerceSelectors } from "./redux";

const EcommercePage = ({
  user,
  banner,
  available,
  onClickGetStarted,
  onClickCancel,
  onClickRetry,
  modal,
  disabledForUser,
}) => {
  return (
    <>
      <Main.Body>
        <Card.Stack>
          <WideBanner
            clientName={AuthUtil.getDisplayName(user)}
            banner={banner}
          />
          <IntegrationCard
            onClickGetStarted={onClickGetStarted}
            available={available}
            disabled={false}
            modal={modal}
            onClickCancel={onClickCancel}
            onClickRetry={onClickRetry}
            disabledForUser={disabledForUser}
          />
        </Card.Stack>
      </Main.Body>
    </>
  );
};

const mapStateToProps = state => ({
  user: AuthSelectors.getUser(state),
  available: EcommerceSelectors.getAvailability(state),
  disabledForUser: EcommerceSelectors.getDisabledForUser(state),
  banner: {
    promo_message_highlight: M.PROMO_TEXT(),
    promo_image: icon,
  },
  modal: EcommerceSelectors.isModalShown(state),
});

const mapDispatchToProps = (dispatch, { withErrorNotification }) => ({
  fetchAvailability: withErrorNotification(
    () => dispatch(EcommerceActions.fetchAvailability()),
    { entityName: M.AVAILABILITY }
  ),
  onClickRetry: () => {
    dispatch(EcommerceActions.hideModal);
    dispatch(EcommerceActions.startGenerateToken());
  },
  onClickCancel: () => {
    dispatch(EcommerceActions.hideModal);
  },
  onClickGetStarted: () => {
    dispatch(EcommerceActions.startGenerateToken());
  },
});

EcommercePage.propTypes = {
  user: PropTypes.object,
  available: PropTypes.bool,
  banner: PropTypes.object,
  onClickGetStarted: PropTypes.func,
  modal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickRetry: PropTypes.func,
  disabledForUser: PropTypes.bool,
};

export default compose(
  withNotification,
  connect(mapStateToProps, mapDispatchToProps),
  withAppLoader(async ({ fetchAvailability }) => await fetchAvailability()),
  withTrack({
    loadId: ECOMMERCE_IDS.LOAD,
    interfaceId: ECOMMERCE_IDS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onClickGetStarted: () =>
      createLogEntryPayload(ECOMMERCE_IDS.CLICK_GET_STARTED),
  }))
)(EcommercePage);
