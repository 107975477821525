import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";

import * as F from "../../../../../constants/forms/invoiceDefaults";
import * as S from "../../../../../constants/strings";
import styles from "../InvoiceDefaults.module.scss";

const SenderInvoiceDetails = () => {
  return (
    <Card className={styles.card}>
      <Card.Header>{S.SENDER_INVOICE_DETAILS}</Card.Header>
      <Container className="p-0">
        <Row>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.COMPANY_NAME}
              name={F.COMPANY_NAME}
              maxLength={100}
            />
          </Col>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.PROPERTY}
              name={F.PROPERTY}
              maxLength={100}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.ADDRESS_1}
              name={F.ADDRESS_1}
              maxLength={100}
            />
          </Col>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.ADDRESS_2}
              name={F.ADDRESS_2}
              maxLength={100}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.ADDRESS_3}
              name={F.ADDRESS_3}
              maxLength={100}
            />
          </Col>
          <Col sm={6}>
            <Field
              component={FormControl.Input}
              label={S.ADDRESS_4}
              name={F.ADDRESS_4}
              maxLength={100}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.POSTCODE}
              name={F.POSTCODE}
              maxLength={10}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.CONTACT_NAME}
              name={F.CONTACT_NAME}
              maxLength={50}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.CONTACT_NUMBER}
              name={F.CONTACT_NUMBER}
              maxLength={50}
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default SenderInvoiceDetails;
