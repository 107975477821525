import { EcommerceActionTypes } from "./actions";

export const initialState = {
  available: false,
  message: null,
  showModal: false,
  disabledForUser: false,
};

export const EcommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case EcommerceActionTypes.FETCH_AVAILABILITY.SUCCESS: {
      return {
        ...state,
        available: action.payload.data.available,
        disabledForUser: action.payload.data.disabledForUser,
        message: null,
      };
    }
    case EcommerceActionTypes.FETCH_AVAILABILITY.FAILURE: {
      return {
        ...state,
        message: action.payload.error.message,
      };
    }
    case EcommerceActionTypes.GENERATE_AUTH_TOKEN.FAILURE: {
      return {
        ...state,
        message: action.payload.error.message,
      };
    }
    case EcommerceActionTypes.SHOW_MODAL: {
      return {
        ...state,
        showModal: true,
      };
    }
    case EcommerceActionTypes.HIDE_MODAL: {
      return {
        ...state,
        showModal: false,
      };
    }
    default:
      return state;
  }
};
