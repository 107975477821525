import PropTypes from "prop-types";
import { Field } from "redux-form";

import { Card, Form } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M } from "../../../../../constants";

const LaserPrinterSettings = ({ onInputChange, isSectionDisabled }) => (
  <Card>
    <Card.Header>{M.LASER_PRINTER_SETTINGS}</Card.Header>
    <Field
      name={F.PRINTING_SETTINGS.LABELS_PER_PAGE}
      id={F.PRINTING_SETTINGS.ONE_LABEL_PER_PAGE}
      value={F.PRINTING_SETTINGS.ONE_LABEL_PER_PAGE}
      component={Form.Check}
      parse={parseInt}
      label={M.$_LABELS_PER_PAGE(1)}
      onChange={onInputChange}
      type="radio"
      disabled={isSectionDisabled}
    />
    <Field
      name={F.PRINTING_SETTINGS.LABELS_PER_PAGE}
      id={F.PRINTING_SETTINGS.TWO_LABELS_PER_PAGE}
      value={F.PRINTING_SETTINGS.TWO_LABELS_PER_PAGE}
      component={Form.Check}
      parse={parseInt}
      label={M.$_LABELS_PER_PAGE(2)}
      onChange={onInputChange}
      type="radio"
      disabled={isSectionDisabled}
    />
  </Card>
);

LaserPrinterSettings.propTypes = {
  isSectionDisabled: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
};

export default withTrackProps(() => ({
  onInputChange: () =>
    A.PRINTING_SETTINGS_IDS.CLICK_RADIO_BUTTON_LABELS_PER_PAGE,
}))(LaserPrinterSettings);
