import { get, pick } from "lodash";

import { F } from "../../../../../constants";
import { dateUtils } from "../../../../../util";
import moment from "moment";
import { DAY_DEFAULT_DATE_FORMAT } from "../../../../../constants/dateFormats";

export const mapDataToForm = shippingDefaults => ({
  ...prepareDataToForm(
    shippingDefaults,
    F.SHIPPING_DEFAULTS.COLLECTION_DEFAULT_FIELDS
  ),
  ...prepareDataToForm(
    get(shippingDefaults, "domestic", {}),
    F.SHIPPING_DEFAULTS.DOMESTIC_FIELDS
  ),
  ...prepareDataToForm(
    get(shippingDefaults, "international", {}),
    F.SHIPPING_DEFAULTS.INTERNATIONAL_FIELDS
  ),
  ...prepareDataToForm(
    get(shippingDefaults, "international.dimension", {}),
    F.SHIPPING_DEFAULTS.DIMENSION_FIELDS
  ),
});

const prepareDataToForm = (shippingDefaults, fieldNames) =>
  fieldNames.reduce((acc, field) => {
    if (F.SHIPPING_DEFAULTS.DATE_FIELDS.includes(field)) {
      const date = get(shippingDefaults, field);
      const isWeekendEnabled = get(shippingDefaults, "enableWeekend", false);
      acc[field] = transformDate(date, isWeekendEnabled);
    } else if (F.SHIPPING_DEFAULTS.BOOL_FIELDS.includes(field)) {
      acc[field] = Boolean(get(shippingDefaults, field, false));
    } else if (
      field === F.SHIPPING_DEFAULTS.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE
    ) {
      acc[field] = get(shippingDefaults, field, 1);
    } else if (field === F.SHIPPING_DEFAULTS.DOM_NUMBER_OF_ITEMS) {
      acc[field] = get(shippingDefaults, field, null);
    } else {
      acc[field] = get(shippingDefaults, field);
    }
    return acc;
  }, {});

export const prepareUpdateData = data => ({
  ...pick(data, F.SHIPPING_DEFAULTS.COLLECTION_DEFAULT_FIELDS),
  defaultShipmentDate: dateUtils.getISODateString(data.defaultShipmentDate),
  domestic: {
    ...pick(data, F.SHIPPING_DEFAULTS.DOMESTIC_FIELDS),
    [F.SHIPPING_DEFAULTS.PRODUCT]:
      get(data, F.SHIPPING_DEFAULTS.PRODUCT) || null,
    [F.SHIPPING_DEFAULTS.SERVICE]:
      get(data, F.SHIPPING_DEFAULTS.SERVICE) || null,
  },
  international: {
    dimension: pick(data, F.SHIPPING_DEFAULTS.DIMENSION_FIELDS),
    ...pick(data, F.SHIPPING_DEFAULTS.INTERNATIONAL_FIELDS),
    [F.SHIPPING_DEFAULTS.PRODUCT_SERVICE]:
      get(data, F.SHIPPING_DEFAULTS.PRODUCT_SERVICE) || null,
  },
});

export const transformDate = (dateString, isWeekendEnabled) => {
  const today = new Date();
  let date = dateString ? new Date(dateString) : today;

  if (date < today) {
    date = today;
  }

  if (!isWeekendEnabled) {
    date = dateUtils.getWeekendNextMonday(date, isWeekendEnabled);
  }

  return moment(date, DAY_DEFAULT_DATE_FORMAT).format(DAY_DEFAULT_DATE_FORMAT);
};
