import { noop } from "lodash";
import PropTypes from "prop-types";
import { withProps } from "recompose";
import { compose } from "redux";

import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { Modal } from "../../../../components/Modal";
import { IMPORTANT_NOTICE_MODAL } from "../../../../constants/analytics";

const ImportantNoticeModal = ({ modalContent, onConfirm }) => (
  <Modal
    modalContent={modalContent}
    onConfirm={onConfirm}
    confirmButtonText="OK"
  />
);

ImportantNoticeModal.propTypes = {
  modalContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    icon: PropTypes.string,
    enabled: PropTypes.string.isRequired,
  }),
  onConfirm: PropTypes.func.isRequired,
};

export default compose(
  withProps(() => ({
    onConfirm: noop,
  })),
  withTrackProps({
    onConfirm: IMPORTANT_NOTICE_MODAL.ON_OK,
  }),
  withTrack(trackProps(IMPORTANT_NOTICE_MODAL))
)(ImportantNoticeModal);
