import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";

import * as F from "../../../../../constants/forms/invoiceDefaults";
import * as S from "../../../../../constants/strings";
import styles from "../InvoiceDefaults.module.scss";
import { M, MAX_LENGTHS } from "../../../../../constants";

const InvoiceDetails = ({ onInputChange }) => {
  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardTitle}>
        {S.INVOICE_DETAILS}
      </Card.Header>
      <Container className="p-0">
        <Row>
          <Col sm={4}>
            <Field
              component={FormControl.Dropdown}
              values={F.INVOICE_SELECTION_VALUES}
              name={F.INVOICE_SELECTION}
              label={S.INVOICE_SELECTION}
              textTransform={S.UPPERCASE}
              onChange={() => {
                onInputChange(F.INVOICE_SELECTION);
              }}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Dropdown}
              values={F.INVOICE_TYPE_VALUES}
              name={F.INVOICE_TYPE}
              label={S.INVOICE_TYPE}
              textTransform={S.UPPERCASE}
              onChange={() => {
                onInputChange(F.INVOICE_SELECTION);
              }}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.EORI_NUMBER}
              name={F.EORI_NUMBER}
              maxLength={25}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.VAT_NUMBER}
              name={F.VAT_NUMBER}
              maxLength={25}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.UKIMS_NUMBER}
              name={F.UKIMS_NUMBER}
              maxLength={MAX_LENGTHS.UKIMS_NUMBER}
              helperText={M.MAX_$_CHARACTERS(MAX_LENGTHS.UKIMS_NUMBER)}
            />
          </Col>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.DESTINATION_TAX_ID_NUMBER}
              name={F.DESTINATION_TAX_ID_NUMBER}
              maxLength={50}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              component={FormControl.Input}
              label={S.FDA_REGISTRATION_NUMBER}
              name={F.FDA_REGISTRATION_NUMBER}
              maxLength={50}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              classes={{ input: styles.multiline }}
              component={FormControl.Input}
              label={S.PAGE_HEADER}
              name={F.PAGE_HEADER}
              helperText={`${
                S.WHEN_NOT_USING_LETTERHRAD_PAPER
              }, ${S.MAX_$_CHARACTERS(255)}`}
              maxLength={255}
              multiline
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

InvoiceDetails.propTypes = {
  onInputChange: PropTypes.func,
};
export default InvoiceDetails;
