import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import { toUppercaseValues } from "../../../util/object";

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/INITIALIZE": {
      return {
        ...state,
        values: toUppercaseValues(cloneDeep(action.payload)),
      };
    }
    case "@@redux-form/BLUR":
    case "@@redux-form/AUTOFILL":
      if (typeof action.payload === "string") {
        return {
          ...state,
          values: set(
            cloneDeep(state.values),
            action.meta.field,
            action.payload.toUpperCase()
          ),
        };
      }
      return state;
    default:
      return state;
  }
};
