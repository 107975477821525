import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Card } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../../../constants";
import { ErrorModal } from "../ErrorModal";
import style from "./IntegrationCard.module.scss";
import {
  EcommerceAvailable,
  EcommerceComing,
  EcommerceDisabled,
} from "./subComponents";

const IntegrationCard = ({
  disabled,
  available,
  modal,
  onClickGetStarted,
  onClickCancel,
  onClickRetry,
  disabledForUser,
}) => {
  return (
    <Card className={style.integration}>
      <Container>
        <Row className={style.integrationWrapper}>
          <Col>
            <Card.Title className={style.integrationTitle}>
              {M.ECOMMERCE_INTEGRATION}
            </Card.Title>
            <Card.Text className={style.integrationText}>
              {M.INTEGRATION_TEXT()}
            </Card.Text>
          </Col>
          <Col lg={3} className={style.available}>
            <Container className={style.availableWrapper}>
              {disabled || disabledForUser ? (
                <EcommerceDisabled />
              ) : (
                <>
                  <Row className={style.availableTitle}>{M.INTERESTED}</Row>
                  {available ? (
                    <>
                      <EcommerceAvailable onClick={onClickGetStarted} />
                      <ErrorModal
                        modal={modal}
                        onClickCancel={onClickCancel}
                        onClickRetry={onClickRetry}
                      />
                    </>
                  ) : (
                    <EcommerceComing />
                  )}
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

IntegrationCard.propTypes = {
  available: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickGetStarted: PropTypes.func,
  modal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickRetry: PropTypes.func,
  disabledForUser: PropTypes.bool,
};

export default IntegrationCard;
