import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, Form } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M } from "../../../../../constants";
import { onlyBoolean } from "../../../../../util/normalize";

const ScanningDefaults = ({ onInputChange }) => (
  <Card>
    <Card.Header>{M.SCANNING_DEFAULTS}</Card.Header>
    <Container className="p-0">
      <Row>
        <Col>
          <Field
            name={F.SHIPPING_DEFAULTS.PRINT_ON_SCAN}
            id={M.AUTO_PRINT}
            component={Form.Check}
            label={M.AUTO_PRINT}
            onChange={() => {
              onInputChange(M.AUTO_PRINT);
            }}
            value={F.SHIPPING_DEFAULTS.AUTO_PRINT}
            normalize={onlyBoolean}
            type="radio"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name={F.SHIPPING_DEFAULTS.PRINT_ON_SCAN}
            id={M.CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES}
            component={Form.Check}
            label={M.CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES}
            onChange={() => {
              onInputChange(M.CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES);
            }}
            value={
              F.SHIPPING_DEFAULTS.CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES
            }
            normalize={onlyBoolean}
            type="radio"
          />
        </Col>
      </Row>
    </Container>
  </Card>
);

ScanningDefaults.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default withTrackProps(() => ({
  onInputChange: type => {
    switch (type) {
      case M.AUTO_PRINT:
        return A.SHIPPING_DEFAULTS_IDS.CLICK_RADIO_BUTTON_AUTO_PRINT;
      case M.CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES:
      default:
        return A.SHIPPING_DEFAULTS_IDS.CLICK_RADIO_BUTTON_TOTAL_NO_OF_PACKAGES;
    }
  },
}))(ScanningDefaults);
