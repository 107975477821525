import PropTypes from "prop-types";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import errorIcon from "../../../../assets/icons/error-icon.svg";
import { M } from "../../../../constants";
import style from "./ErrorModal.module.scss";

const IntegrationCard = ({ modal, onClickCancel, onClickRetry }) => {
  return (
    <Modal
      show={modal}
      backdrop="static"
      className={style.modal}
      centered
      contentClassName={style.modalContent}
    >
      <Modal.Header className={style.modalHeader}>
        {M.SOMETHINGS_GONE_WRONG}
      </Modal.Header>
      <Modal.Body className={style.modalBody}>
        <img className={style.warningIcon} src={errorIcon} />
        {M.GENERATE_TOKEN_FAILURE()}
      </Modal.Body>
      <Modal.Footer className={style.modalFooter}>
        <Button variant="dark" onClick={onClickCancel}>
          {M.CANCEL}
        </Button>
        <Button onClick={onClickRetry}>{M.RETRY}</Button>
      </Modal.Footer>
    </Modal>
  );
};

IntegrationCard.propTypes = {
  modal: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickRetry: PropTypes.func,
};

export default IntegrationCard;
