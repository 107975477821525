import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { ecommerceApis } from "../../../apis";

export const EcommerceActionTypes = createActionTypes("ECOMMERCE", {
  FETCH_AVAILABILITY: createAsyncActionTypes("FETCH_AVAILABILITY"),
  GENERATE_AUTH_TOKEN: createAsyncActionTypes("GENERATE_AUTH_TOKEN"),
  START_GENERATE_TOKEN: createAsyncActionTypes("START_GENERATE_TOKEN"),
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
});

export const fetchAvailability = createAsyncAction(
  ecommerceApis.fetchAvailability,
  EcommerceActionTypes.FETCH_AVAILABILITY
);

export const generateAuthToken = createAsyncAction(
  ecommerceApis.generateAuthToken,
  EcommerceActionTypes.GENERATE_AUTH_TOKEN
);

export const startGenerateToken = createAsyncAction(
  () => async dispatch => {
    try {
      const res = await dispatch(generateAuthToken());
      const { redirectUri } = res.data;
      window.location = redirectUri;
    } catch (err) {
      dispatch(showModal);
    }
  },
  EcommerceActionTypes.START_GENERATE_TOKEN
);

export const showModal = createAction(EcommerceActionTypes.SHOW_MODAL);

export const hideModal = createAction(EcommerceActionTypes.HIDE_MODAL);
