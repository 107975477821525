import get from "lodash/get";

import {
  FIELDS,
  TITLE,
  TITLE_VALUES,
} from "../../../../../constants/forms/contactDetails";

export const mapDataToForm = user => {
  return FIELDS.reduce((acc, field) => {
    const defaultValue = field === TITLE ? TITLE_VALUES[0].value : "";
    acc[field] = get(user, field, defaultValue);
    return acc;
  }, {});
};
