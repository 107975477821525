import get from "lodash/get";

import {
  PRINTER_DRIVER_TYPE,
  PRINTER_TYPE,
  PRINTER_USB_VENDOR_ID,
} from "@dpdgroupuk/mydpd-enums";

import { DPD_LABEL_PRINTER, F } from "../../../../../constants";
import { removeNullBytes } from "../../../../../util/string";
import { isUnixSystem } from "../../../../../util/system";

export const mapDataToForm = ({ prefsConfigSettings, printers }) =>
  F.PRINTING_SETTINGS.FIELDS.reduce((acc, field) => {
    const fieldData =
      get(prefsConfigSettings, field) ||
      F.PRINTING_SETTINGS.DEFAULT_VALUES[field];

    switch (field) {
      case F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER:
        acc[field] =
          fieldData === F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER_VALUE;
        break;
      case F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME:
        // Find a user's printer in the list or set to empty
        acc[field] = findConfiguredPrinterName(
          prefsConfigSettings?.thermalPrinterName,
          printers
        );
        break;
      case F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE: {
        // cover the case when user switched from different or browser where WebUSB was supported
        if (
          !isSupportedWebUsbDriver() &&
          fieldData === PRINTER_DRIVER_TYPE.WEB_USB
        ) {
          // use mydpd driver by default
          acc[field] = PRINTER_DRIVER_TYPE.MYDPD_SERVICE;
          break;
        }
        acc[field] = fieldData;
        break;
      }

      default:
        acc[field] = fieldData;
    }

    return acc;
  }, {});

export const prepareUpdateData = formValues => {
  delete formValues.missingAssetNumber;
  return formValues;
};

export const getThermalPrinterTypeByVendorId = vendorId => {
  switch (vendorId) {
    case PRINTER_USB_VENDOR_ID.TSC:
    case PRINTER_USB_VENDOR_ID.CITIZEN:
    case PRINTER_USB_VENDOR_ID.CITIZEN_CL521:
      return PRINTER_TYPE.CITIZEN.toString();
    case PRINTER_USB_VENDOR_ID.ZEBRA:
      return PRINTER_TYPE.ELTRON.toString();
    default:
      return PRINTER_TYPE.LASER.toString();
  }
};

export const mapUsbDeviceToFormFields = device => {
  const fields = {
    [F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE]: getThermalPrinterTypeByVendorId(
      device.vendorId
    ),
    [F.PRINTING_SETTINGS.THERMAL_PRINTER_VENDOR_ID]: device.vendorId,
    [F.PRINTING_SETTINGS.THERMAL_PRINTER_PRODUCT_ID]: device.productId,
  };
  const assetsNumber = removeNullBytes(device.serialNumber).trim();
  if (assetsNumber) {
    fields[F.PRINTING_SETTINGS.THERMAL_PRINTER_ASSET_NUMBER] = assetsNumber;
  }

  return fields;
};

export const isTestPrinterAvailable = (thermalPrinterName, printers) =>
  printers.some(printer => printer.deviceName === thermalPrinterName);

export const findConfiguredPrinterName = (thermalPrinterName, printers) =>
  printers.find(printer => printer.deviceName === thermalPrinterName)
    ?.deviceName || "";

export const isConfigurePrinterAvailable = printers =>
  isUnixSystem() &&
  !printers.find(printer => printer.deviceName === DPD_LABEL_PRINTER);

// disable temporary support of WebUsbApi
export const isSupportedWebUsbDriver = () => false;
