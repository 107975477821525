import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field } from "redux-form";

import { Card, Form, FormControl, Legend } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M, MAX_LENGTHS } from "../../../../constants";
import { withFormController } from "../../../../hocs";
import { UserModel } from "../../../../models";
import { ReferenceSelectors } from "../../../../store/reference";
import { integerNormalize } from "../../../../util/normalize";
import PageContainer from "../../components/PageContainer";
import { AccountDetailsActions } from "../../redux";
import styles from "./CollectionDefaults.module.scss";
import {
  CollectionDefaultsActions,
  CollectionDefaultsModel,
  CollectionDefaultsValidators,
} from "./redux";

const CollectionDefaults = ({
  user,
  onSubmit,
  onInputChange,
  products,
  services,
}) => (
  <PageContainer onClick={onSubmit}>
    <Legend rightMessage={UserModel.getUserDetailsString(user)} />
    <Card.Stack>
      <Col sm={12}>
        <Card className={styles.card}>
          <Card.Header>{M.COLLECTION_DEFAULT_DETAILS}</Card.Header>
          <Row>
            <Col>
              <Field
                component={Form.Check}
                name={F.COLLECTION_DEFAULTS.PRE_ARRANGED}
                id={F.COLLECTION_DEFAULTS.PRE_ARRANGED}
                label={M.DO_NOT_PROMPT_FOR_COLLECTION}
                onChange={() => {
                  onInputChange(F.COLLECTION_DEFAULTS.PRODUCT);
                }}
                type="checkbox"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                component={FormControl.Dropdown}
                name={F.COLLECTION_DEFAULTS.PRODUCT}
                label={M.PRODUCT}
                values={products}
                placeholder={M.SELECT_$(M.PRODUCT)}
                textTransform={M.UPPERCASE}
                onChange={() => {
                  onInputChange(F.COLLECTION_DEFAULTS.PRODUCT);
                }}
              />
            </Col>
            <Col sm={6}>
              <Field
                component={FormControl.Dropdown}
                label={M.SERVICE}
                name={F.COLLECTION_DEFAULTS.SERVICE}
                values={services}
                placeholder={M.SELECT_$(M.SERVICE)}
                textTransform={M.UPPERCASE}
                onChange={() => {
                  onInputChange(F.COLLECTION_DEFAULTS.PRODUCT);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Field
                component={FormControl.Input}
                label={M.NUMBER_OF_ITEMS}
                name={F.COLLECTION_DEFAULTS.NUMBER_OF_ITEMS}
                maxLength={MAX_LENGTHS.NUMBER_OF_ITEMS}
                normalize={integerNormalize}
              />
            </Col>

            <Col sm={4}>
              <Field
                component={FormControl.Input}
                label={M.WEIGHT}
                name={F.COLLECTION_DEFAULTS.WEIGHT}
                maxLength={MAX_LENGTHS.WEIGHT}
                normalize={integerNormalize}
              />
            </Col>

            <Col sm={4}>
              <Field
                component={FormControl.Input}
                label={M.CLOSING_TIME}
                name={F.COLLECTION_DEFAULTS.CLOSING_TIME}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Card.Stack>
  </PageContainer>
);

CollectionDefaults.propTypes = {
  user: PropTypes.object.isRequired,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
  onSubmit: PropTypes.func,
  services: PropTypes.array,
  products: PropTypes.array,
};

const mapStateToProps = state => ({
  products: ReferenceSelectors.getProductsKeyValue(state),
  services: ReferenceSelectors.getServicesKeyValue(state),
});

export default compose(
  connect(mapStateToProps),
  withFormController({
    form: F.FORMS_NAMES.COLLECTION_DETAILS_FORM,
    validate: CollectionDefaultsValidators.validate,
    onLoad: AccountDetailsActions.fetchPreferences,
    onSubmit: CollectionDefaultsActions.updateForm,
    mapDataToForm: ({ prefsCollectionDefaults }) =>
      CollectionDefaultsModel.mapDataToForm(prefsCollectionDefaults),
    entityName: M.PREFERENCES,
    touchOnChange: true,
  }),
  withTrack({
    loadId: A.COLLECTION_DEFAULTS_IDS.LOAD,
    interfaceId: A.COLLECTION_DEFAULTS_IDS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onSubmit: () => A.COLLECTION_DEFAULTS_IDS.CLICK_SAVE,
    onInputChange: type => {
      switch (type) {
        case F.COLLECTION_DEFAULTS.PRODUCT:
          return A.COLLECTION_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_PRODUCT;
        case F.COLLECTION_DEFAULTS.SERVICE:
          return A.COLLECTION_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_SERVICE;
      }
    },
  }))
)(CollectionDefaults);
