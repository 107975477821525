import { createSelector } from "reselect";

export const getData = state => state.app.ecommerce;

export const getAvailability = createSelector(
  getData,
  ({ available = false }) => {
    return available;
  }
);

export const getDisabledForUser = createSelector(
  getData,
  ({ disabledForUser }) => {
    return disabledForUser;
  }
);

export const isModalShown = createSelector(getData, ({ showModal = false }) => {
  return showModal;
});
