import { LocalApisClient } from "@dpdgroupuk/mydpd-app";

class LocalSettingsApisClient {
  constructor(client) {
    this.client = client;
  }

  saveWorkstationConfig(config) {
    return this.client.post({
      path: "/settings",
      body: config,
    });
  }
}

class LocalDriversApisClient {
  constructor(client) {
    this.client = client;
  }

  configureUsbPrinter() {
    return this.client.post({
      path: "/drivers/setup",
    });
  }
}

export class LogonLocalApisClient extends LocalApisClient {
  constructor(options) {
    super(options);
    this.settings = new LocalSettingsApisClient(this);
    this.drivers = new LocalDriversApisClient(this);
  }
}
