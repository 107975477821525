import { isEmpty } from "lodash";

import { M } from "../../../../../constants";
import {
  DIM_WEIGHT,
  DOM_WEIGHT,
  INT_WEIGHT,
  NUMBER_FIELDS,
  RANGE_VALIDATE_FIELDS,
} from "../../../../../constants/forms/shippingDefaults";

export const validate = (values, formProps) => {
  const errors = {};

  if (!formProps.initialized) {
    return errors;
  }

  const numberErrors = NUMBER_FIELDS.reduce((acc, key) => {
    const numberOfItems = parseFloat(values[key]);

    if (!isEmpty(values[key]) && (numberOfItems < 1 || numberOfItems > 99)) {
      acc[key] = M.MUST_BE_BETWEEN_$(1, 99);
    }
    return acc;
  }, {});

  const integerErrors = NUMBER_FIELDS.reduce((acc, key) => {
    const value = values[key];

    if (value && (isNaN(value) || !Number.isInteger(parseFloat(value)))) {
      acc[key] = M.MUST_BE_A_POSITIVE_WHOLE_NUMBER;
    }
    return acc;
  }, {});

  const weightErrors = [DOM_WEIGHT, INT_WEIGHT].reduce((acc, key) => {
    const weight = parseFloat(values[key]);

    if (!isEmpty(values[key]) && (weight < 0.1 || weight > 999)) {
      acc[key] = M.MUST_BE_BETWEEN_$(0.1, "999 KG");
    }
    return acc;
  }, {});

  const dimWeight = parseFloat(values[DIM_WEIGHT]);

  const dimWeightError =
    !isEmpty(values[DIM_WEIGHT]) && (dimWeight < 0.001 || dimWeight > 99999)
      ? { [DIM_WEIGHT]: M.MUST_BE_BETWEEN_$(0.001, "99999 KG") }
      : {};

  const invalidFields = RANGE_VALIDATE_FIELDS.reduce((acc, key) => {
    const value = parseInt(values[key]);

    if (value > 175 || value <= 0 || (isNaN(value) && values[key])) {
      acc[key] = M.MUST_BE_WITHIN_RANGE;
    }
    return acc;
  }, {});

  return {
    ...errors,
    ...invalidFields,
    ...numberErrors,
    ...integerErrors,
    ...weightErrors,
    ...dimWeightError,
  };
};
