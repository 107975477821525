import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../../../apis";
import { invoiceDefaultsValidators } from "./index";
import { SubmissionError } from "redux-form";

export const invoiceDefaultsActionTypes = createActionTypes(
  "INVOICE_DEFAULTS",
  {
    UPDATE_INVOICE_SETTINGS: createAsyncActionTypes("UPDATE_INVOICE_SETTINGS"),
    UPDATE_FORM: createAsyncActionTypes("UPDATE_FORM"),
  }
);

export const updateForm = createAsyncAction(
  body => userApis.updateInvoiceDefaults(body),
  invoiceDefaultsActionTypes.UPDATE_INVOICE_SETTINGS
);

export const submitFormWithValidation = createAsyncAction(
  values => dispatch => {
    const formErrors = invoiceDefaultsValidators.validate(values);
    if (Object.keys(formErrors).length > 0) {
      throw new SubmissionError(formErrors);
    } else {
      return dispatch(updateForm(values));
    }
  },
  invoiceDefaultsActionTypes.UPDATE_FORM
);
