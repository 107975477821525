import { NO, ORGANISATION_NAME, POST_CODE, SHORT_NAME, YES } from "../strings";

// Fields
export const SHARE_PROFILE = "shareProfile";
export const SHARE_PRODUCT_BOOK = "shareProductbook";
export const COUNTRY = "intCountry";
export const SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE = "shipmentAddressSearchType";
export const DEFAULT_SHIPMENT_DATE = "defaultShipmentDate";
export const ENABLE_WEEKEND = "enableWeekend";
export const DEFAULT_INFORMATION_1 = "defaultInformation1";
export const DEFAULT_INFORMATION_2 = "defaultInformation2";
export const DEFAULT_INFORMATION_3 = "defaultInformation3";
export const DEFAULT_INFORMATION_4 = "defaultInformation4";
export const DEFAULT_INFORMATION_5 = "defaultInformation5";
export const PRINT_ON_SCAN = "printOnScan";

export const PRODUCT_SERVICE = "intProduct";
export const INT_NUMBER_OF_ITEMS = "intNumItems";
export const INT_WEIGHT = "intWeight";
export const INT_REFERENCE_1_PREFIX = "intReferenceText";
export const EXTENDED_LIABILITY = "intLiability";
export const EXTENDED_LIABILITY_VALUE = "intLiabilityValue";
export const CURRENCY = "intCurrency";
export const CONTENT_DESCRIPTION = "intContentDescription";

export const PRODUCT = "domProduct";
export const SERVICE = "domService";
export const DOM_NUMBER_OF_ITEMS = "domNumItems";
export const DOM_WEIGHT = "domWeight";
export const ADDRESS_ON_LABEL = "domAddressOnLabel";
export const DOM_REFERENCE_1_PREFIX = "domReferenceText";
export const DEFAULT_BULK_REVERSE_IT_REFERENCE = "bulkReverseRef";

export const DIM_WEIGHT = "intDimWeight";
export const DIM_WIDTH = "intDimWidth";
export const DIM_LENGTH = "intDimLength";
export const DIM_HEIGHT = "intDimHeight";

export const AUTO_PRINT = true;
export const CURSOR_GOES_STRAIGHT_TO_TOTAL_NO_OF_PACKAGES = false;

// Dropdown values
export const SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE_VALUES = [
  { value: 1, label: SHORT_NAME },
  { value: 2, label: ORGANISATION_NAME },
  { value: 3, label: POST_CODE },
];

export const EXTENDED_LIABILITY_VALUES = [
  { value: true, label: YES },
  { value: false, label: NO },
];

export const COLLECTION_DEFAULT_FIELDS = [
  SHARE_PROFILE,
  SHARE_PRODUCT_BOOK,
  COUNTRY,
  SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE,
  DEFAULT_SHIPMENT_DATE,
  ENABLE_WEEKEND,
  DEFAULT_INFORMATION_1,
  DEFAULT_INFORMATION_2,
  DEFAULT_INFORMATION_3,
  DEFAULT_INFORMATION_4,
  DEFAULT_INFORMATION_5,
  PRINT_ON_SCAN,
];

export const DOMESTIC_FIELDS = [
  PRODUCT,
  SERVICE,
  DOM_NUMBER_OF_ITEMS,
  DOM_WEIGHT,
  ADDRESS_ON_LABEL,
  DOM_REFERENCE_1_PREFIX,
  DEFAULT_BULK_REVERSE_IT_REFERENCE,
];

export const INTERNATIONAL_FIELDS = [
  PRODUCT_SERVICE,
  INT_NUMBER_OF_ITEMS,
  INT_WEIGHT,
  INT_REFERENCE_1_PREFIX,
  EXTENDED_LIABILITY,
  EXTENDED_LIABILITY_VALUE,
  CURRENCY,
  CONTENT_DESCRIPTION,
];

export const DIMENSION_FIELDS = [DIM_WEIGHT, DIM_WIDTH, DIM_LENGTH, DIM_HEIGHT];

export const DATE_FIELDS = [DEFAULT_SHIPMENT_DATE];

export const BOOL_FIELDS = [
  SHARE_PROFILE,
  SHARE_PRODUCT_BOOK,
  ENABLE_WEEKEND,
  ADDRESS_ON_LABEL,
];

export const RADIO_FIELDS = [PRINT_ON_SCAN];

export const RANGE_VALIDATE_FIELDS = [DIM_WIDTH, DIM_LENGTH, DIM_HEIGHT];

export const NUMBER_FIELDS = [INT_NUMBER_OF_ITEMS, DOM_NUMBER_OF_ITEMS];

export const WEIGHT_FIELDS = [DIM_WEIGHT, DOM_WEIGHT, INT_WEIGHT];
