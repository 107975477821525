import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field } from "redux-form";

import { Card, Form, FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M, MAX_LENGTHS } from "../../../../../constants";
import {
  floatNormalize,
  integerNormalize,
} from "../../../../../util/normalize";

const DomesticShippingDefaults = ({ products, services, onInputChange }) => (
  <Card>
    <Card.Header>{M.DOMESTIC_SHIPPING_DEFAULTS}</Card.Header>
    <Container className="p-0">
      <Row>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.PRODUCT}
            component={FormControl.Dropdown}
            label={M.PRODUCT}
            values={[
              { label: M.PLEASE_SELECT_$(M.PRODUCT), value: null },
              ...products,
            ]}
            textTransform={M.UPPERCASE}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.PRODUCT);
            }}
            placeholder={M.PLEASE_SELECT_$(M.PRODUCT)}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.SERVICE}
            component={FormControl.Dropdown}
            label={M.SERVICE}
            values={[
              { label: M.PLEASE_SELECT_$(M.SERVICE), value: null },
              ...services,
            ]}
            textTransform={M.UPPERCASE}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.SERVICE);
            }}
            placeholder={M.PLEASE_SELECT_$(M.SERVICE)}
          />
        </Col>
        <Col sm={2}>
          <Field
            name={F.SHIPPING_DEFAULTS.DOM_NUMBER_OF_ITEMS}
            component={FormControl.Input}
            label={M.NUMBER_OF_ITEMS}
            maxLength={MAX_LENGTHS.NUMBER_OF_ITEMS}
            normalize={integerNormalize}
          />
        </Col>
        <Col sm={2}>
          <Field
            name={F.SHIPPING_DEFAULTS.DOM_WEIGHT}
            component={FormControl.Input}
            label={M.WEIGHT}
            maxLength={MAX_LENGTHS.WEIGHT}
            normalize={floatNormalize}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.ADDRESS_ON_LABEL}
            id={F.SHIPPING_DEFAULTS.ADDRESS_ON_LABEL}
            component={Form.Check}
            label={M.SENDERS_ADDRESS_ON_LABEL}
            type="checkbox"
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DOM_REFERENCE_1_PREFIX}
            component={FormControl.Input}
            label={M.REFERENCE_1_PREFIX}
            maxLength={50}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_BULK_REVERSE_IT_REFERENCE}
            component={FormControl.Input}
            label={M.DEFAULT_BULK_REFERENCE}
            maxLength={12}
          />
        </Col>
      </Row>
    </Container>
  </Card>
);

DomesticShippingDefaults.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  products: PropTypes.array,
  services: PropTypes.array,
};

export default compose(
  withTrackProps(() => ({
    onInputChange: type => {
      switch (type) {
        case F.SHIPPING_DEFAULTS.PRODUCT:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_PRODUCT;
        case F.SHIPPING_DEFAULTS.SERVICE:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_SERVICE;
        case F.SHIPPING_DEFAULTS.ADDRESS_ON_LABEL:
        default:
          return A.SHIPPING_DEFAULTS_IDS
            .CLICK_CHECKBOX_SENDERS_ADDRESS_ON_LABEL;
      }
    },
  }))
)(DomesticShippingDefaults);
