import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M, MAX_LENGTHS } from "../../../../../constants";
import {
  floatNormalize,
  integerNormalize,
  onlyBoolean,
} from "../../../../../util/normalize";

const InternationalShippingDefaults = ({
  networks,
  currencies,
  onInputChange,
}) => (
  <Card>
    <Card.Header>{M.INTERNATIONAL_SHIPPING_DEFAULTS}</Card.Header>
    <Container className="p-0">
      <Row>
        <Col sm={3}>
          <Field
            name={F.SHIPPING_DEFAULTS.PRODUCT_SERVICE}
            component={FormControl.Dropdown}
            values={[
              { label: M.SELECT_$(`${M.PRODUCT}/${M.SERVICE}`), value: null },
              ...networks,
            ]}
            label={`${M.PRODUCT} / ${M.SERVICE}`}
            textTransform={M.UPPERCASE}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.PRODUCT_SERVICE);
            }}
            placeholder={M.SELECT_$(`${M.PRODUCT}/${M.SERVICE}`)}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.SHIPPING_DEFAULTS.INT_NUMBER_OF_ITEMS}
            component={FormControl.Input}
            label={M.NUMBER_OF_ITEMS}
            maxLength={MAX_LENGTHS.NUMBER_OF_ITEMS}
            normalize={integerNormalize}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.SHIPPING_DEFAULTS.INT_WEIGHT}
            component={FormControl.Input}
            label={M.WEIGHT}
            maxLength={MAX_LENGTHS.WEIGHT}
            normalize={floatNormalize}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.SHIPPING_DEFAULTS.INT_REFERENCE_1_PREFIX}
            component={FormControl.Input}
            label={M.REFERENCE_1_PREFIX}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.EXTENDED_LIABILITY}
            component={FormControl.Dropdown}
            label={M.EXTENDED_LIABILITY}
            textTransform={M.UPPERCASE}
            values={F.SHIPPING_DEFAULTS.EXTENDED_LIABILITY_VALUES}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.EXTENDED_LIABILITY);
            }}
            normalize={onlyBoolean}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.EXTENDED_LIABILITY_VALUE}
            component={FormControl.Input}
            label={M.EXTENDED_LIABILITY_VALUE}
            maxLength={10}
            normalize={integerNormalize}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.CURRENCY}
            component={FormControl.Dropdown}
            label={M.CURRENCY}
            textTransform={M.UPPERCASE}
            values={currencies}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.CURRENCY);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Field
            name={F.SHIPPING_DEFAULTS.CONTENT_DESCRIPTION}
            component={FormControl.Input}
            label={M.CONTENT_DESCRIPTION}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
      </Row>
    </Container>
  </Card>
);

InternationalShippingDefaults.propTypes = {
  currencies: PropTypes.array,
  networks: PropTypes.array,
  onInputChange: PropTypes.func.isRequired,
};

export default compose(
  withTrackProps(() => ({
    onInputChange: type => {
      switch (type) {
        case F.SHIPPING_DEFAULTS.PRODUCT_SERVICE:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_PRODUCT_SERVICE;
        case F.SHIPPING_DEFAULTS.EXTENDED_LIABILITY:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_EXTENDED_LIABILITY;
        case F.SHIPPING_DEFAULTS.CURRENCY:
        default:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_CURRENCY;
      }
    },
  }))
)(InternationalShippingDefaults);
