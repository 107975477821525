import { isEmpty } from "lodash";

import { M, REGEX } from "../../../../../constants";
import {
  CLOSING_TIME,
  INTEGER_FIELDS,
  NUMBER_OF_ITEMS,
  WEIGHT,
} from "../../../../../constants/forms/collectionDefaults";

export const validate = values => {
  const errors = {};
  const numberOfItems = parseFloat(values[NUMBER_OF_ITEMS]);
  const weight = parseFloat(values[WEIGHT]);

  const numberError =
    !isEmpty(values[NUMBER_OF_ITEMS]) &&
    (numberOfItems < 1 || numberOfItems > 99)
      ? { [NUMBER_OF_ITEMS]: M.MUST_BE_BETWEEN_$(1, 99) }
      : {};

  const weightError =
    !isEmpty(values[WEIGHT]) && (weight < 1 || weight > 999)
      ? { [WEIGHT]: M.MUST_BE_BETWEEN_$(1, "999 KG") }
      : {};

  const integerErrors = INTEGER_FIELDS.reduce((acc, key) => {
    const value = values[key];

    if (value && (isNaN(value) || !Number.isInteger(parseFloat(value)))) {
      acc[key] = M.MUST_BE_A_POSITIVE_WHOLE_NUMBER;
    }
    return acc;
  }, {});

  if (
    values[CLOSING_TIME] &&
    values[CLOSING_TIME].trim() &&
    !REGEX.TIME_REGEX.test(values[CLOSING_TIME])
  ) {
    errors[CLOSING_TIME] = M.INVALID_TIME;
  }

  return { ...errors, ...weightError, ...numberError, ...integerErrors };
};
