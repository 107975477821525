import { createSelector } from "reselect";

import { EUR, GB, GBP, IE } from "../../constants/strings";

export const getData = state => state.app.reference;

export const getCountries = createSelector(getData, ({ countries = [] }) =>
  countries.sort((a, b) => {
    if (a.countryKey === GB) return -1;
    if (b.countryKey === GB) return 1;
    if (a.countryKey === IE) return -1;
    if (b.countryKey === IE) return 1;

    return a.countryName > b.countryName ? 1 : -1;
  })
);

export const getCountriesKeyValue = createSelector(getCountries, countries =>
  countries.map(({ countryKey: value, countryName: label }) => ({
    label,
    value,
  }))
);

export const getCurrencies = createSelector(getData, ({ currencies = [] }) =>
  currencies.sort((a, b) => {
    if (a.currencyCode === GBP) return -1;
    if (b.currencyCode === GBP) return 1;
    if (a.currencyCode === EUR) return -1;
    if (b.currencyCode === EUR) return 1;

    return a.currencyCode > b.currencyCode ? 1 : -1;
  })
);

export const getCurrenciesKeyValue = createSelector(getCurrencies, currencies =>
  currencies.map(currency => ({
    label: `${currency.currencyDescription} (${currency.currencyCode})`,
    value: currency.currencyCode,
  }))
);

export const getProducts = createSelector(
  getData,
  ({ products = [] }) => products
);

export const getProductsKeyValue = createSelector(getProducts, products =>
  products.map(product => ({
    label: product.productDescription,
    value: product.productCode,
  }))
);

export const getServices = createSelector(
  getData,
  ({ services = [] }) => services
);

export const getServicesKeyValue = createSelector(getServices, services =>
  services.map(service => ({
    label: service.serviceDescription,
    value: service.serviceCode,
  }))
);

export const getNetworks = createSelector(
  getData,
  ({ networks = [] }) => networks
);

export const getNetworksKeyValue = createSelector(getNetworks, networks =>
  networks.map(network => ({
    label: network.networkDescription,
    value: network.networkCode,
  }))
);
