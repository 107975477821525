import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Card, FormControl } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../../../../constants";
import * as F from "../../../../../constants/forms/shippingDefaults";
import {
  floatNormalize,
  integerNormalize,
} from "../../../../../util/normalize";

const Dimension = () => (
  <Card>
    <Card.Header>{M.DIMENSION}</Card.Header>
    <Container className="p-0">
      <Row>
        <Col sm={3}>
          <Field
            name={F.DIM_WEIGHT}
            component={FormControl.Input}
            label={M.WEIGHT}
            maxLength={5}
            normalize={floatNormalize}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.DIM_LENGTH}
            component={FormControl.Input}
            label={M.LENGTH}
            maxLength={10}
            normalize={integerNormalize}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.DIM_WIDTH}
            component={FormControl.Input}
            label={M.WIDTH}
            maxLength={10}
            normalize={integerNormalize}
          />
        </Col>
        <Col sm={3}>
          <Field
            name={F.DIM_HEIGHT}
            component={FormControl.Input}
            label={M.HEIGHT}
            maxLength={10}
            normalize={integerNormalize}
          />
        </Col>
      </Row>
    </Container>
  </Card>
);

export default Dimension;
