import { SalesforceActions } from "./index";

export const initialState = {
  message: null,
  showModal: false,
  isLoading: false,
};

export const SalesforceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SalesforceActions.ACTIONS.START_FETCH_SUPPORT_URL.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SalesforceActions.ACTIONS.FETCH_SUPPORT_URL.FAILURE: {
      return {
        ...state,
        message: action.payload.error.message,
        isLoading: false,
      };
    }
    case SalesforceActions.ACTIONS.FETCH_SUPPORT_URL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SalesforceActions.ACTIONS.SHOW_MODAL: {
      return {
        ...state,
        showModal: true,
      };
    }
    case SalesforceActions.ACTIONS.HIDE_MODAL: {
      return {
        ...state,
        showModal: false,
      };
    }
    default:
      return state;
  }
};
