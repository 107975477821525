import { PRINTER_DRIVER_TYPE } from "@dpdgroupuk/mydpd-enums";

import { M, REGEX } from "../../../../../constants";
import {
  PRINT_TO_THERMAL,
  THERMAL_PRINTER_ASSET_NUMBER,
  THERMAL_PRINTER_DRIVER_TYPE,
  THERMAL_PRINTER_NAME,
  THERMAL_PRINTER_PRODUCT_ID,
  THERMAL_PRINTER_VENDOR_ID,
} from "../../../../../constants/forms/printingSettings";

export const validate = props => {
  const errors = {};
  if (props[PRINT_TO_THERMAL]) {
    if (!props[THERMAL_PRINTER_DRIVER_TYPE]) {
      errors[THERMAL_PRINTER_DRIVER_TYPE] = M.FIELD_IS_MANDATORY;
    }
    if (
      props[THERMAL_PRINTER_DRIVER_TYPE] === PRINTER_DRIVER_TYPE.MYDPD_SERVICE
    ) {
      if (!props[THERMAL_PRINTER_NAME]) {
        errors[THERMAL_PRINTER_NAME] = M.FIELD_IS_MANDATORY;
      }

      if (!props[THERMAL_PRINTER_ASSET_NUMBER]) {
        errors[THERMAL_PRINTER_ASSET_NUMBER] = M.FIELD_IS_MANDATORY;
      }
    }

    if (props[THERMAL_PRINTER_DRIVER_TYPE] === PRINTER_DRIVER_TYPE.WEB_USB) {
      if (!props[THERMAL_PRINTER_VENDOR_ID]) {
        errors[THERMAL_PRINTER_VENDOR_ID] = M.FIELD_IS_MANDATORY;
      }
      if (!props[THERMAL_PRINTER_PRODUCT_ID]) {
        errors[THERMAL_PRINTER_PRODUCT_ID] = M.FIELD_IS_MANDATORY;
      }
    }
  }

  return errors;
};

export const validateWorkstationConfig = props => {
  const errors = {};
  if (props.workstationId) {
    if (!REGEX.ALPHANUMERIC_ONLY.test(props.workstationId)) {
      errors.workstationId = M.ALLOWED_CHARACTERS_$(["a-z", "A-Z", "0-9"]);
    }
  }

  return errors;
};
