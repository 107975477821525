import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Col } from "react-bootstrap";

import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import Icon from "../../components/Icon";
import styles from "./SmallCard.module.scss";

export const SmallCard = ({ cardContent, onSelect }) => {
  const theme = useMyDpdTheme();
  const CardIcon = Icon[cardContent.icon];

  const onLinkSelected = async e => {
    const onLoad = cardContent.onLoad;
    if (onLoad && typeof onLoad === "function") {
      await onLoad();
    }
    return onSelect(e);
  };

  return (
    <Col sm>
      <Card className={classNames(styles.card, "border-0")}>
        <a
          className={styles.link}
          href={
            cardContent.link ? cardContent.link : undefined // this excludes href from the html when not set
          }
          onClick={onLinkSelected}
        >
          <CardIcon fill={theme.palette.brand} className={styles.linkIcon} />
          <h1 className={styles.linkTitle}>{cardContent.title}</h1>
        </a>
      </Card>
    </Col>
  );
};

SmallCard.propTypes = {
  cardContent: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    onLoad: PropTypes.func,
  }),
  onSelect: PropTypes.func,
};
