import PropTypes from "prop-types";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { compose, withProps } from "recompose";
import { Field, propTypes, reduxForm } from "redux-form";

import { withLocalServiceState } from "@dpdgroupuk/mydpd-app";
import { Button, Form, FormControl } from "@dpdgroupuk/mydpd-ui";

import { WORKSTATION_CONFIG_FORM } from "../../../../../../constants/forms/formsNames";
import * as S from "../../../../../../constants/strings";
import { validateWorkstationConfig } from "../../redux/validator";
import styles from "./WorkStationConfigModal.module.scss";

const WorkstationConfigModal = props => (
  <Modal
    show={props.show}
    backdrop="static"
    centered
    contentClassName={styles.modalContent}
  >
    <Modal.Header onHide={props.onClose} className={styles.modalHeader}>
      Multi Workstation
    </Modal.Header>
    <Modal.Body className={styles.modalBody}>
      <Container className="p-0">
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={"Auto Workstation ID"}
              name={"workstationId"}
              maxLength={10}
              helperText={S.MAX_$_CHARACTERS(10)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name={"polling"}
              id={"workstation-polling"}
              component={Form.Check}
              label={"Enable Polling"}
              type="checkbox"
            />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer className={styles.modalFooter}>
      <Button.ButtonGroup className={styles.modalButtons}>
        <Button
          variant="dark"
          onClick={() => {
            props.onClose();
            props.reset();
          }}
        >
          {S.CANCEL}
        </Button>
        <Button
          disabled={props.invalid || props.submitting || props.pristine}
          onClick={props.handleSubmit(props.onSubmit)}
          variant="primary"
        >
          {S.SAVE}
        </Button>
      </Button.ButtonGroup>
    </Modal.Footer>
  </Modal>
);

WorkstationConfigModal.propTypes = {
  onClose: PropTypes.func,
  ...propTypes,
};

export default compose(
  withLocalServiceState,
  withProps(props => ({
    initialValues: props.localServiceState.appSettings,
  })),
  reduxForm({
    form: WORKSTATION_CONFIG_FORM,
    shouldError: () => true,
    enableReinitialize: true,
    validate: validateWorkstationConfig,
    onSubmit: (values, _, props) => props.saveWorkstationConfig(values),
  })
)(WorkstationConfigModal);
