import PropTypes from "prop-types";

import { Button, Main } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../../../constants";

const PageContainer = ({ buttonText = M.SAVE, onClick, children }) => {
  return (
    <>
      <Main.Body>{children}</Main.Body>
      <Main.Footer className="dark">
        <Button.Toolbar>
          <Button onClick={onClick}>{buttonText}</Button>
        </Button.Toolbar>
      </Main.Footer>
    </>
  );
};

PageContainer.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  submitting: PropTypes.bool,
};

export default PageContainer;
