import { UserActionTypes } from "./actions";

export const initialState = {
  user: {},
  advancedConfiguration: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.FETCH_USER.SUCCESS: {
      return {
        ...state,
        user: action.payload.data,
      };
    }
    case UserActionTypes.FETCH_ADVANCED_CONFIGURATIONS.SUCCESS: {
      return {
        ...state,
        advancedConfiguration: action.payload.data,
      };
    }
    default:
      return state;
  }
};
