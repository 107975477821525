import { API_ENDPOINTS } from "../constants";
import { apisClient as api } from "./api";

export const fetchUser = () => api.get(API_ENDPOINTS.USER);

export const fetchPreferences = () =>
  api.get(`${API_ENDPOINTS.USER}/preferences`);

export const fetchAdvancedConfiguration = () =>
  api.get(`${API_ENDPOINTS.USER}/advancedConfiguration`);

export const updateContactDetails = body =>
  api.put({
    path: `${API_ENDPOINTS.USER}/contactSettings`,
    body,
  });

export const updatePrintingSettings = body =>
  api.put({
    path: `${API_ENDPOINTS.USER}/printerSettings`,
    body,
  });

export const updateCollectionDefaults = body =>
  api.put({
    path: `${API_ENDPOINTS.USER}/collectionSettings`,
    body,
  });

export const updateShippingDefaults = body =>
  api.put({
    path: `${API_ENDPOINTS.USER}/shippingSettings`,
    body,
  });

export const updateInvoiceDefaults = body =>
  api.put({
    path: `${API_ENDPOINTS.USER}/invoiceSettings`,
    body,
  });
