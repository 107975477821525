import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { useUrlResolver } from "@dpdgroupuk/mydpd-app";
import { Button } from "@dpdgroupuk/mydpd-ui";

import icon from "../../../../../../assets/icons/download-arrow.svg";
import infoIcon from "../../../../../../assets/icons/info.svg";
import linkIcon from "../../../../../../assets/icons/link.svg";
import { isDpdLocalDomain } from "../../../../../../util/env";
import styles from "./MyDPDSettings.module.scss";

const DownloadInstallerRow = ({ className }) => {
  const urlResolver = useUrlResolver();
  return (
    <Row className={classNames(className, "d-flex")}>
      <Col sm={12} md={8} lg={9}>
        <p className={classNames(styles.installText)}>
          To use the MyDPD Customer App, you must first download and install the
          installation file.
        </p>
      </Col>
      <Col sm={12} md={4} lg={3}>
        <Button
          type="link"
          href={`${urlResolver.getUrl("mydpd.apis")}/apps/download`}
          className={styles.printerButton}
        >
          Download Installation file
          <img alt="Download" className={styles.printerButtonIcon} src={icon} />
        </Button>
      </Col>
      <Col sm={12} md={12} lg={12} className={"d-flex align-items-start"}>
        <span>
          <img
            alt="Info"
            src={infoIcon}
            className={classNames(styles.infoIcon)}
          />
        </span>
        <span className="d-inline-block ml-2">
          {" "}
          <p className={classNames(styles.helpText)}>
            Once downloaded, navigate to your Downloads folder and double click
            on the file that has downloaded. Once the file has been installed,
            double click on the MyDPD Customer icon on the Desktop.
          </p>
        </span>
      </Col>
      <Col sm={12} md={12} lg={12} className={"d-flex align-items-start"}>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            isDpdLocalDomain()
              ? "https://www.dpdlocal.co.uk/lp/mydpd-features/index.html#printer-setup"
              : "https://www.dpd.co.uk/lp/mydpd-features/index.html#printer-setup"
          }
        >
          <span className={classNames(styles.tutorial)}>
            View video tutorial
          </span>
          <span>
            <img
              alt="Info"
              src={linkIcon}
              className={classNames(styles.tutorialIcon)}
            />
          </span>
        </a>
      </Col>
    </Row>
  );
};

DownloadInstallerRow.propTypes = {
  className: PropTypes.string,
};

export default DownloadInstallerRow;
