import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../apis";
import { AuthSelectors } from "../../../store/auth";
import { ReferenceActions } from "../../../store/reference";
import { UserActions } from "../../../store/user";

export const PreferencesActionTypes = createActionTypes("PREFERENCES", {
  FETCH_PREFERENCES: createAsyncActionTypes("FETCH_PREFERENCES"),
  FETCH_ADVANCED_CONFIGURATIONS: createAsyncActionTypes(
    "FETCH_ADVANCED_CONFIGURATIONS"
  ),
});

export const fetchAppData = () => (dispatch, getState) => {
  const { businessId } = AuthSelectors.getUser(getState());

  return Promise.all([
    dispatch(ReferenceActions.fetchProducts({ businessId })),
    dispatch(ReferenceActions.fetchServices({ businessId })),
    dispatch(ReferenceActions.fetchNetworks()),
    dispatch(ReferenceActions.fetchCountries()),
    dispatch(ReferenceActions.fetchCurrencies()),
    dispatch(UserActions.fetchUserAdvancedConfiguration()),
  ]);
};

export const fetchPreferences = createAsyncAction(
  userApis.fetchPreferences,
  PreferencesActionTypes.FETCH_PREFERENCES
);
