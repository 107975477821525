import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../../../apis";

export const CollectionDefaultsActionTypes = createActionTypes(
  "COLLECTION_DEFAULTS",
  {
    UPDATE_COLLECTION_DEFAULTS: createAsyncActionTypes(
      "UPDATE_COLLECTION_DEFAULTS"
    ),
  }
);

export const updateForm = createAsyncAction(
  body => userApis.updateCollectionDefaults(body),
  CollectionDefaultsActionTypes.UPDATE_COLLECTION_DEFAULTS
);
