import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { AuthUtil, withAppLoader } from "@dpdgroupuk/mydpd-app";
import { Card, Main } from "@dpdgroupuk/mydpd-ui";

import icon from "../../assets/icons/api.svg";
import { WideBanner } from "../../components/WideBanner";
import { M } from "../../constants";
import { withNotification } from "../../hocs";
import { AuthSelectors } from "../../store/auth";
import { IntegrationCard } from "./components/IntegrationCard";

const ApiPage = ({ user, banner }) => {
  return (
    <>
      <Main.Body>
        <Card.Stack>
          <WideBanner
            clientName={AuthUtil.getDisplayName(user)}
            banner={banner}
          />
          <IntegrationCard />
        </Card.Stack>
      </Main.Body>
    </>
  );
};

const mapStateToProps = state => ({
  user: AuthSelectors.getUser(state),
  banner: {
    promo_message_highlight: M.API_PROMO_TEXT(),
    promo_image: icon,
  },
});

ApiPage.propTypes = {
  user: PropTypes.object,
  banner: PropTypes.object,
};

export default compose(
  withNotification,
  connect(mapStateToProps),
  withAppLoader(async () => {})
)(ApiPage);
