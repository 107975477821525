import PropTypes from "prop-types";

const CreateNewShipment = ({ fill = "#DC0032", className }) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M50.057 34.5C49.929 34.628 49.752 34.707 49.557 34.707L18.4435 34.7075C18.0525 34.7075 17.7365 34.3915 17.7365 34.0005C17.7365 33.6095 18.0525 33.2935 18.4435 33.2935L49.557 33.293C49.948 33.293 50.264 33.609 50.264 34C50.2635 34.1955 50.185 34.372 50.057 34.5Z"
        fill="#414042"
      />
      <path
        d="M34.5 50.057C34.372 50.185 34.195 50.264 34 50.264C33.609 50.264 33.293 49.948 33.293 49.557L33.2935 18.4435C33.2935 18.0525 33.6095 17.7365 34.0005 17.7365C34.3915 17.7365 34.7075 18.0525 34.7075 18.4435L34.707 49.557C34.707 49.752 34.628 49.929 34.5 50.057Z"
        fill="#414042"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="48.0833"
          height="48.0833"
          fill="white"
          transform="translate(0 34) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
);

CreateNewShipment.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default CreateNewShipment;
