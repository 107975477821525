import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import { AccountPage, ApiPage, EcommercePage, WelcomePage } from "../pages";
import {
  ACCOUNT_PAGE,
  API_PAGE,
  E_COMMERCE_PAGE,
  WELCOME_PAGE,
} from "./constants";

export const Routes = () => (
  <Switch>
    <Redirect exact from="/" to={WELCOME_PAGE} />
    <Route path={WELCOME_PAGE} exact component={WelcomePage} />
    <Route path={API_PAGE} exact component={ApiPage} />
    <Route path={ACCOUNT_PAGE} component={AccountPage} />
    <Route path={E_COMMERCE_PAGE} component={EcommercePage} />
    <Redirect to={WELCOME_PAGE} />
  </Switch>
);
