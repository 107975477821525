import { LocalProvider, PROJECT_NAME, Provider } from "@dpdgroupuk/mydpd-app";
import { Layout } from "@dpdgroupuk/mydpd-ui";

import { apisClient, localApis, tracker } from "./apis";
import Header from "./Header";
import { Routes } from "./router";

const App = () => (
  <Provider
    apis={apisClient}
    projectName={PROJECT_NAME.MYDPD}
    tracker={tracker}
  >
    <Layout header={<Header />}>
      <LocalProvider localApis={localApis}>
        {/* <AutoUpdater /> */}
        <Routes />
      </LocalProvider>
    </Layout>
  </Provider>
);

export default App;
