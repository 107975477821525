import React from "react";

import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { PRINTER_DRIVER_TYPE } from "@dpdgroupuk/mydpd-enums";
import { Card, Form } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { StatusLabel } from "../../../../../../components/Label";
import { A, F, M } from "../../../../../../constants";
import { isWindowsSystem } from "../../../../../../util/system";
import ThermalServiceDetails from "./ThermalServiceDetails";
import ThermalWebUsbDetails from "./ThermalWebUsbDetails";

const ThermalPrinterSettings = props => {
  const ThermalPrinterDetails = React.useMemo(() => {
    return props.printerDriverType === PRINTER_DRIVER_TYPE.MYDPD_SERVICE
      ? ThermalServiceDetails
      : props.printerDriverType === PRINTER_DRIVER_TYPE.WEB_USB
      ? ThermalWebUsbDetails
      : () => null;
  }, [props.printerDriverType]);
  return (
    <Card>
      <Card.Header>{M.THERMAL_PRINTER_SETTINGS}</Card.Header>
      <Container className="p-0">
        <Row>
          <Col>
            <Field
              name={F.PRINTING_SETTINGS.PRINT_TO_THERMAL}
              id={F.PRINTING_SETTINGS.PRINT_TO_THERMAL}
              component={Form.Check}
              label={M.PRINTING_TO_THERMAL_PRINTER}
              onChange={() => {
                props.onInputChange(F.PRINTING_SETTINGS.PRINT_TO_THERMAL);
              }}
              type="checkbox"
            />
          </Col>
        </Row>
        {props.printToThermal && (
          <>
            {props.supportsWebUsbApi && (
              <Row className="mb-4">
                <Col>
                  <Field
                    name={F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE}
                    id="thermalPrinterDriverType-2"
                    value={PRINTER_DRIVER_TYPE.MYDPD_SERVICE}
                    component={({ input, meta, ...props }) => (
                      <Form.Check
                        {...input}
                        {...props}
                        className={
                          meta.error && meta.touched ? "text-danger" : null
                        }
                      />
                    )}
                    parse={parseInt}
                    label={
                      <StatusLabel
                        label={M.USE_PRINTER_VIA_MYDPD_CUSTOMER}
                        failed={!props.localService.state.isRunning}
                        failMessage="App is not running"
                      />
                    }
                    onChange={() => {
                      props.onInputChange(
                        F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE
                      );
                    }}
                    type="radio"
                    disabled={!props.localService.state.isRunning}
                  />
                  <Field
                    name={F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE}
                    id="thermalPrinterDriverType-1"
                    value={PRINTER_DRIVER_TYPE.WEB_USB}
                    component={({ input, meta, ...props }) => (
                      <Form.Check
                        {...input}
                        {...props}
                        className={
                          meta.error && meta.touched ? "text-danger" : null
                        }
                      />
                    )}
                    parse={parseInt}
                    label={
                      <StatusLabel
                        label={M.USE_PRINTER_VIA_BROWSER_USB}
                        failed={!props.supportsWebUsbApi}
                        failMessage={M.BROWSER_USB_PRINTING_IS_NOT_SUPPORTED_BY_$(
                          isWindowsSystem() ? "Operating System" : "Browser"
                        )}
                      />
                    }
                    onChange={() => {
                      props.onInputChange(
                        F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE
                      );
                    }}
                    type="radio"
                    disabled={!props.supportsWebUsbApi}
                  />
                </Col>
              </Row>
            )}
            <ThermalPrinterDetails {...props} />
          </>
        )}
      </Container>
    </Card>
  );
};

ThermalPrinterSettings.propTypes = {
  localService: PropTypes.object,
  supportsWebUsbApi: PropTypes.bool,
  printers: PropTypes.array,
  printToThermal: PropTypes.bool,
  printerDriverType: PropTypes.number,
  isTestPrinterAvailable: PropTypes.bool,
  isConfigureAvailable: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  isAssetNumberDisabled: PropTypes.bool,
  onTestPrinter: PropTypes.func,
  onConfigurePrinter: PropTypes.func,
  onConfigureUsbWebPrinter: PropTypes.func,
  onChangeMissingAssetNumberField: PropTypes.func,
};

export default withTrackProps(() => ({
  onChangeMissingAssetNumberField:
    A.PRINTING_SETTINGS_IDS.CLICK_CHECKBOX_ASSET_NUMBER,
  onInputChange: type => {
    switch (type) {
      case F.PRINTING_SETTINGS.PRINT_TO_THERMAL:
        return A.PRINTING_SETTINGS_IDS
          .CLICK_CHECKBOX_PRINTING_TO_THERMAL_PRINTER;
      case F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE:
        return A.PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_TYPE;
      case F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME:
        return A.PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_NAME;
    }
  },
}))(ThermalPrinterSettings);
