import {
  COMMERCIAL,
  CREATE_OWN_INVOICE,
  GENERATE_INVOICE_ONLINE,
  PROFORMA,
  SELECT_EACH_TIME_INVOICE,
} from "../strings";

// Fields
export const INVOICE_SELECTION = "createInvoice";
export const INVOICE_TYPE = "invoiceType";
export const VAT_NUMBER = "vatNumber";
export const DESTINATION_TAX_ID_NUMBER = "destinationTaxIdRegNo";
export const FDA_REGISTRATION_NUMBER = "fdaRegistrationNumber";
export const EORI_NUMBER = "eoriNumber";
export const PAGE_HEADER = "pageHeader";
export const COMPANY_NAME = "companyName";
export const PROPERTY = "property";
export const ADDRESS_1 = "address1";
export const ADDRESS_2 = "address2";
export const ADDRESS_3 = "address3";
export const ADDRESS_4 = "address4";
export const POSTCODE = "postcode";
export const CONTACT_NAME = "contactName";
export const CONTACT_NUMBER = "contactNumber";
export const UKIMS_NUMBER = "ukimsNumber";

export const FIELDS = [
  INVOICE_SELECTION,
  INVOICE_TYPE,
  VAT_NUMBER,
  PAGE_HEADER,
  COMPANY_NAME,
  PROPERTY,
  ADDRESS_1,
  ADDRESS_2,
  ADDRESS_3,
  ADDRESS_4,
  POSTCODE,
  CONTACT_NAME,
  CONTACT_NUMBER,
  DESTINATION_TAX_ID_NUMBER,
  FDA_REGISTRATION_NUMBER,
  EORI_NUMBER,
  UKIMS_NUMBER,
];

// Dropdown values
export const INVOICE_SELECTION_VALUES = [
  { label: GENERATE_INVOICE_ONLINE, value: "G" },
  { label: SELECT_EACH_TIME_INVOICE, value: "S" },
  { label: CREATE_OWN_INVOICE, value: "C" },
];

export const INVOICE_TYPE_VALUES = [
  { label: PROFORMA, value: "P" },
  { label: SELECT_EACH_TIME_INVOICE, value: "S" },
  { label: COMMERCIAL, value: "C" },
];
