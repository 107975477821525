import PropTypes from "prop-types";

const MyAccount = ({ fill = "#DC0032", className }) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M37 33.717C36.597 33.717 36.216 33.47 36.064 33.071C35.869 32.555 36.128 31.977 36.645 31.782C39.594 30.665 41.499 27.92 41.499 24.789C41.499 20.653 38.135 17.289 33.999 17.289C29.863 17.289 26.499 20.653 26.499 24.789C26.499 27.92 28.404 30.664 31.353 31.782C31.869 31.978 32.129 32.555 31.934 33.072C31.739 33.589 31.16 33.848 30.644 33.653C26.969 32.26 24.499 28.699 24.499 24.79C24.499 19.552 28.761 15.29 33.999 15.29C39.237 15.29 43.499 19.552 43.499 24.79C43.499 28.699 41.029 32.261 37.353 33.653C37.237 33.696 37.118 33.717 37 33.717Z"
      fill="#414042"
    />
    <path d="M49 52.711C48.447 52.711 48 52.264 48 51.711C48 43.991 41.72 37.711 34 37.711C30.71 37.711 27.513 38.875 24.999 40.988C21.822 43.658 20 47.566 20 51.711C20 52.264 19.552 52.711 19 52.711C18.448 52.711 18 52.264 18 51.711C18 46.975 20.082 42.508 23.711 39.457C26.586 37.041 30.239 35.711 34 35.711C42.822 35.711 50 42.889 50 51.711C50 52.264 49.553 52.711 49 52.711Z" />
  </svg>
);

MyAccount.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default MyAccount;
