import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { F, M } from "../../../../../../constants";

const ThermalWebUsbDetails = ({
  supportsWebUsbApi,
  onConfigureUsbWebPrinter,
}) => {
  return (
    <>
      <Row>
        <Col sm={6} md={4} lg={4}>
          <Field
            name={F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE}
            component={FormControl.Dropdown}
            label={M.THERMAL_PRINTER_TYPE}
            textTransform={M.UPPERCASE}
            values={F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPES}
            maxLength={50}
            disabled={true}
          />
          <Field
            name={F.PRINTING_SETTINGS.THERMAL_PRINTER_ASSET_NUMBER}
            component={FormControl.Input}
            label={M.THERMAL_PRINTER_ASSET_NUMBER}
            maxLength={50}
            disabled={!supportsWebUsbApi}
          />
        </Col>
        <Col sm={6} md={4} lg={4}>
          <Field
            name={F.PRINTING_SETTINGS.THERMAL_PRINTER_VENDOR_ID}
            component={FormControl.Input}
            label={M.THERMAL_PRINTER_VENDOR}
            textTransform={M.UPPERCASE}
            required
            maxLength={6}
            disabled={true}
          />
          <Field
            name={F.PRINTING_SETTINGS.THERMAL_PRINTER_PRODUCT_ID}
            component={FormControl.Input}
            label={M.THERMAL_PRINTER_PRODUCT}
            required
            maxLength={6}
            disabled={true}
          />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <Button
            size="sm"
            variant="dark"
            className="mb-4"
            disabled={!supportsWebUsbApi}
            onClick={onConfigureUsbWebPrinter}
          >
            {M.CONFIGURE_BROWSER_USB_PRINTER}
          </Button>
        </Col>
      </Row>
    </>
  );
};

ThermalWebUsbDetails.propTypes = {
  supportsWebUsbApi: PropTypes.bool,
  onConfigureUsbWebPrinter: PropTypes.func,
  ...propTypes,
};

export default ThermalWebUsbDetails;
