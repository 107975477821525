import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../../../apis";
import { prepareUpdateData } from "./model";
import { ShippingDefaultsSelectors } from "./index";
import { get } from "lodash";
import { F } from "../../../../../constants";
import moment from "moment";
import { change } from "redux-form";
import { getMinShipmentDate } from "../../../../../util/date";
import { DAY_DEFAULT_DATE_FORMAT } from "../../../../../constants/dateFormats";

export const ShippingDefaultsActionTypes = createActionTypes(
  "SHIPPING_DEFAULTS",
  {
    UPDATE_SHIPPING_DEFAULTS: createAsyncActionTypes(
      "UPDATE_SHIPPING_DEFAULTS"
    ),
  }
);

export const updateForm = createAsyncAction(
  body => userApis.updateShippingDefaults(prepareUpdateData(body)),
  ShippingDefaultsActionTypes.UPDATE_SHIPPING_DEFAULTS
);

export const checkMinDate = () => (dispatch, getState) => {
  const formValues = ShippingDefaultsSelectors.getShippingDefaultsFormValues(
    getState()
  );
  const shipmentDate = get(
    formValues,
    F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE,
    null
  );
  const minDate = getMinShipmentDate(
    get(formValues, F.SHIPPING_DEFAULTS.ENABLE_WEEKEND, false)
  );

  if (
    moment(shipmentDate, DAY_DEFAULT_DATE_FORMAT).isBefore(
      moment(minDate, DAY_DEFAULT_DATE_FORMAT),
      "day"
    )
  ) {
    dispatch(
      change(
        F.FORMS_NAMES.SHIPPING_DEFAULTS_FORM,
        F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE,
        minDate
      )
    );

    return minDate;
  }

  return shipmentDate;
};
