import { ReferenceActionTypes } from "./actions";

export const initialState = {
  countries: [],
  currencies: [],
  products: [],
  services: [],
  networks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ReferenceActionTypes.FETCH_COUNTRIES.SUCCESS: {
      return {
        ...state,
        countries: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_CURRENCIES.SUCCESS: {
      return {
        ...state,
        currencies: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_PRODUCTS.SUCCESS: {
      return {
        ...state,
        products: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_SERVICES.SUCCESS: {
      return {
        ...state,
        services: action.payload.data,
      };
    }
    case ReferenceActionTypes.FETCH_NETWORKS.SUCCESS: {
      return {
        ...state,
        networks: action.payload.data,
      };
    }
    default:
      return state;
  }
};
