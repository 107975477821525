import { useMemo } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  Header,
  useAppUser,
  useUrlResolver,
  withAppUserPreferences,
} from "@dpdgroupuk/mydpd-app";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { MENU } from "./constants/analytics";
import {
  getPrimaryMenuActions,
  getSecondaryMenu,
  getWelcomeSecondaryMenuActions,
} from "./util/menu";

export const AppHeader = ({
  onPrimaryMenuSelect,
  onSecondaryMenuSelect,
  ...headerProps
}) => {
  const urlResolver = useUrlResolver();
  const user = useAppUser();
  const secondaryMenuItems = useMemo(
    () => getSecondaryMenu(urlResolver, user.appRoles),
    [user.appRoles]
  );
  return (
    <Header
      secondaryMenuItems={secondaryMenuItems}
      onPrimaryMenuSelect={onPrimaryMenuSelect}
      onSelectSecondaryMenu={onSecondaryMenuSelect}
      {...headerProps}
    />
  );
};

AppHeader.propTypes = {
  onPrimaryMenuSelect: PropTypes.func,
  onSecondaryMenuSelect: PropTypes.func,
};

const PRIMARY_INDEX_TO_ACTION_ID = getPrimaryMenuActions();
export default compose(
  withAppUserPreferences,
  connect((state, { appUser }) => ({
    SECONDARY_INDEX_TO_ACTION_ID: getWelcomeSecondaryMenuActions(
      appUser.appRoles
    ),
  })),
  withTrack(MENU.LOAD),
  withTrackProps(({ SECONDARY_INDEX_TO_ACTION_ID }) => ({
    onPrimaryMenuSelect: index => PRIMARY_INDEX_TO_ACTION_ID[index],
    onSecondaryMenuSelect: index => SECONDARY_INDEX_TO_ACTION_ID[index],
    onClickDpdLogo: MENU.ON_CLICK_DPD_LOGO,
    onClickHelp: MENU.ON_CLICK_HELP,
    onClickLogOff: MENU.ON_CLICK_LOGOUT,
  }))
)(AppHeader);
