import classNames from "classnames";
import PropTypes from "prop-types";
import { Container, Row } from "react-bootstrap";

import { Card, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../constants";
import styles from "./InfoList.module.scss";

export const InfoList = ({ title, fields, linkText, linkStyle }) => {
  const dpdThemeName = useMyDpdTheme().themeName;

  if (fields) {
    return (
      <Card className={classNames(styles.info)}>
        <Card.Header>{title}</Card.Header>
        <Container className={styles.infoContainer}>
          {fields.map(info => (
            <Row className={styles.infoText} key={info.text}>
              <span
                className={classNames(
                  styles.infoBulletPoint,
                  styles[dpdThemeName]
                )}
              ></span>
              {info.date && (
                <span className={styles.infoDate}>
                  {info.date.split("T")[0]}
                </span>
              )}

              <span className={styles.infoMessage}>
                &nbsp;{info.date && "- "}
                {info.text}&nbsp;
                {info.link && (
                  <a
                    href={info.link}
                    className={classNames(styles.infoLink, linkStyle)}
                  >
                    {linkText}
                  </a>
                )}
              </span>
            </Row>
          ))}
        </Container>
      </Card>
    );
  } else {
    return null;
  }
};

InfoList.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  linkText: PropTypes.string,
  linkStyle: PropTypes.string,
};

InfoList.defaultProps = {
  linkText: M.READ_MORE,
};
