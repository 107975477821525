import _ from "lodash";

export const toUppercaseValues = (obj, omit = []) =>
  _.mapValues(obj, (value, key) => {
    if (_.isString(value) && !omit.includes(key)) {
      value = value.toUpperCase();
    } else if (_.isPlainObject(value)) {
      value = toUppercaseValues(value);
    }

    return value;
  });
