import { useState } from "react";

import PropTypes from "prop-types";
import { compose } from "redux";

import { useUrlResolver } from "@dpdgroupuk/mydpd-app";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ErrorModal } from "../../../../components/ErrorModal";
import { SmallCard } from "../../../../components/SmallCard";
import { getQuickLinks, QUICK_LINKS_ACTIONS } from "../../../../util/menu";

const QuickLinks = ({ onSelect, loadFns, showModal }) => {
  const urlResolver = useUrlResolver();
  const cards = getQuickLinks(urlResolver, loadFns);

  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const selectedCard = cards[selectedCardIndex];

  const onSmallSelect = index => {
    setSelectedCardIndex(index);
    return onSelect(index);
  };

  const cancelModal = selectedCard => {
    setSelectedCardIndex(null);
    return selectedCard.onClickCancel();
  };

  return (
    <>
      {cards?.map((card, index) => (
        <SmallCard
          key={card.title}
          cardContent={card}
          onSelect={() => onSmallSelect(index)}
        />
      ))}
      {selectedCard && selectedCard.onLoad && (
        <ErrorModal
          showModal={showModal}
          onClickCancel={() => cancelModal(selectedCard)}
          onClickRetry={selectedCard.onClickRetry}
          modalBody={selectedCard.modalBody}
        />
      )}
    </>
  );
};

QuickLinks.propTypes = {
  onSelect: PropTypes.func,
  loadFns: PropTypes.objectOf(PropTypes.objectOf(PropTypes.func)),
  showModal: PropTypes.bool,
};

export default compose(
  withTrackProps({
    onSelect: index => QUICK_LINKS_ACTIONS[index],
  })
)(QuickLinks);
