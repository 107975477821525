import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import styles from "./MyDPDSettings.module.scss";

const StatusRow = ({ isRunning }) => {
  return (
    <Row>
      <Col className={"d-flex align-items-center"}>
        <span
          className={classNames(
            styles.statusIndicator,
            "rounded-circle bg-success",
            isRunning ? "bg-success" : "bg-danger"
          )}
        />
        <span className="d-inline-block ml-2">
          {isRunning ? "App running" : "Stopped or not installed"}
        </span>
      </Col>
    </Row>
  );
};

StatusRow.propTypes = {
  isRunning: PropTypes.bool,
};

export default StatusRow;
