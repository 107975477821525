import { get } from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { initialize, reduxForm } from "redux-form";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { withOverlay } from "@dpdgroupuk/mydpd-ui";

import { AuthSelectors } from "../store/auth";
import withNotification from "./withNotification";

const withFormController = ({
  form,
  onChange,
  validate,
  onLoad,
  onSubmit,
  entityName,
  mapDataToForm,
  touchOnChange,
  shouldError,
}) => {
  const config = {
    form,
    onChange,
    validate,
    touchOnChange,
  };
  typeof shouldError === "function" && (config.shouldError = shouldError);

  return compose(
    withOverlay,
    withNotification,
    reduxForm(config),
    connect(
      state => ({ user: AuthSelectors.getUser(state) }),
      (dispatch, ownProps) => {
        const {
          overlay,
          handleSubmit,
          withErrorNotification,
          withFormSubmitNotification,
        } = ownProps;
        return {
          onLoad: withErrorNotification(() => dispatch(onLoad(ownProps)), {
            entityName,
          }),
          initializeForm: data =>
            dispatch(initialize(form, mapDataToForm(data))),
          onSubmit: handleSubmit(
            withFormSubmitNotification(
              overlay.showWhile(data => dispatch(onSubmit(data)))
            )
          ),
        };
      }
    ),
    withAppLoader(async ({ onLoad, initializeForm }) => {
      const result = await onLoad();

      initializeForm(get(result, "data", {}));
    })
  );
};

export default withFormController;
