import { get } from "lodash";

import { USER_DETAILS_$ } from "../constants/strings";

export const getUsername = user => get(user, "username", "");

export const getUserAccount = user => get(user, "account", "");

export const getUserDetailsString = user =>
  USER_DETAILS_$(getUsername(user), getUserAccount(user));
