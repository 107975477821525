import { Row } from "react-bootstrap";
import { Button } from "@dpdgroupuk/mydpd-ui";
import { M } from "../../../../constants";
import style from "./IntegrationCard.module.scss";

const APIStatus = () => {
  return (
    <>
      <Row className={style.availableText}>{M.USE_THE_API()}</Row>
      <Row>
        <Button
          onClick={() => {
            window.open(process.env.REACT_APP_API_PORTAL_URL, "_blank");
          }}
        >
          {M.API_BUTTON_TEXT}
        </Button>
      </Row>
    </>
  );
};

export default APIStatus;
