import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { Main, Menu, Nav } from "@dpdgroupuk/mydpd-ui";

import { M } from "../../constants";
import { withNotification } from "../../hocs";
import { AccountDetailsActions } from "./redux";
import { PATH, Routes } from "./router";

const MENU_ITEMS = [
  {
    href: PATH.CONTACT_DETAILS,
    name: M.CONTACT_DETAILS,
  },
  {
    href: PATH.PRINTING_SETTINGS,
    name: M.PRINTING_SETTINGS,
  },
  {
    href: PATH.COLLECTION_DEFAULTS,
    name: M.COLLECTION_DEFAULTS,
  },
  {
    href: PATH.SHIPPING_DEFAULTS,
    name: M.SHIPPING_DEFAULTS,
  },
  {
    href: PATH.INVOICE_DEFAULTS,
    name: M.INVOICE_DEFAULTS,
  },
];

const AccountPage = () => {
  return (
    <Main>
      <Main.Header>
        <Menu.Third>
          {MENU_ITEMS.map((item, index) => (
            <Nav.Item key={index}>
              <NavLink to={item.href} className="mydpd-nav-link">
                {item.name}
              </NavLink>
            </Nav.Item>
          ))}
        </Menu.Third>
      </Main.Header>
      <Routes />
    </Main>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch, { withErrorNotification }) => ({
  fetchAppData: withErrorNotification(
    () => dispatch(AccountDetailsActions.fetchAppData()),
    { entityName: M.APP_DATA }
  ),
});

export default compose(
  withNotification,
  connect(mapStateToProps, mapDispatchToProps),
  withAppLoader(async ({ fetchAppData }) => {
    await fetchAppData();
  })
)(AccountPage);
