import { M, REGEX } from "../../../../../constants";
import {
  EMAIL,
  FIELDS,
  TELEPHONE,
} from "../../../../../constants/forms/contactDetails";
import { formatMessage } from "../../../../../util/string";

export const validate = (props, formProps) => {
  const errors = {};
  if (!formProps.initialized) {
    return errors;
  }

  if (props[EMAIL] && props[EMAIL].trim() && !REGEX.EMAIL.test(props[EMAIL])) {
    errors[EMAIL] = M.INVALID_EMAIL;
  }

  if (
    props[TELEPHONE] &&
    props[TELEPHONE].trim() &&
    !REGEX.PHONE.test(props[TELEPHONE])
  ) {
    errors[TELEPHONE] = M.INVALID_TELEPHONE_NUMBER;
  }

  const invalidFields = FIELDS.reduce((acc, key) => {
    if (!props[key] || !props[key].trim()) {
      acc[key] = formatMessage(M.FIELD_CANT_BE_EMPTY, "Field");
    }

    return acc;
  }, {});

  return { ...errors, ...invalidFields };
};
