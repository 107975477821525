import clsx from "clsx";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Card, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import { BANNER_THEMES } from "./themes";
import styles from "./WideBanner.module.scss";

export const WideBanner = ({ clientName, banner, themeName }) => {
  const dpdTheme = useMyDpdTheme();
  const theme = BANNER_THEMES[themeName] || BANNER_THEMES.Welcome;

  return (
    <Card className={clsx(styles.banner, styles["banner" + theme])}>
      <Container>
        <Row
          className={clsx(
            styles.bannerContainer,
            styles["bannerContainer" + theme],
            styles["bannerContainer" + dpdTheme.themeName]
          )}
        >
          <Col sm={6} lg={5}>
            <Container className={styles.greeting}>
              {clientName && (
                <Row className={styles.greetingName} noGutters>
                  {clientName},
                </Row>
              )}
              <Row className={styles.greetingMessage} noGutters>
                {banner?.welcome_message || "Welcome To MyDPD"}
              </Row>
            </Container>
          </Col>
          {banner && (
            <>
              {(banner.promo_message || banner.promo_message_highlight) && (
                <Col sm={6} lg={4}>
                  <Container className={styles.promo}>
                    <Row noGutters className={styles.promoMessage}>
                      <span
                        className={clsx(
                          styles.promoText,
                          styles["promoText" + theme]
                        )}
                      >
                        {banner.promo_message}
                      </span>
                      <span
                        className={clsx(
                          styles.promoTextHighlighted,
                          styles["promoTextHighlighted" + theme]
                        )}
                      >
                        {banner.promo_message_highlight}
                      </span>
                    </Row>
                    {banner.promo_link && (
                      <Row noGutters>
                        <Button
                          variant={dpdTheme.variant}
                          onClick={() =>
                            window.open(banner.promo_link, "_blank")
                          }
                          className={clsx(
                            styles.promoButton,
                            styles["promoButton" + theme],
                            "btn-prima"
                          )}
                        >
                          {banner.promo_button_text}
                        </Button>
                      </Row>
                    )}
                  </Container>
                </Col>
              )}
              <Col
                lg={3}
                className={clsx(
                  styles.image,
                  "d-none d-lg-flex align-self-center"
                )}
              >
                <img
                  className={styles.imagePic}
                  src={`${banner.promo_image}`}
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Card>
  );
};

WideBanner.propTypes = {
  clientName: PropTypes.string,
  banner: PropTypes.shape({
    welcome_message: PropTypes.string,
    promo_message: PropTypes.string,
    promo_message_highlight: PropTypes.string,
    promo_link: PropTypes.string,
    promo_button_text: PropTypes.string,
    promo_image: PropTypes.string,
  }),
  themeName: PropTypes.string,
};
