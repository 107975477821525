import PropTypes from "prop-types";

const HaveAQuery = ({ fill = "#DC0032", className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M24.4714 20.8876C24.3429 20.8876 24.2152 20.8322 24.1273 20.726L22.6503 18.9406C22.4928 18.7505 22.52 18.469 22.7096 18.3123C22.9006 18.1544 23.1818 18.1829 23.3379 18.3717L24.815 20.1571C24.9725 20.3472 24.9453 20.6287 24.7556 20.7854C24.6722 20.8541 24.5709 20.8876 24.4714 20.8876Z"
      fill="#414042"
    />
    <path
      d="M27.0538 20.8871C26.807 20.8871 26.6075 20.6876 26.6075 20.4409V8.83871C26.6075 8.59283 26.4076 8.39247 26.1613 8.39247H10.9691C10.7233 8.39247 10.5229 8.59283 10.5229 8.83871V17.7635C10.5229 18.0098 10.7233 18.2097 10.9691 18.2097H22.9939C23.2407 18.2097 23.4401 18.4092 23.4401 18.6559C23.4401 18.9027 23.2407 19.1022 22.9939 19.1022H10.9691C10.2311 19.1022 9.63042 18.5015 9.63042 17.7635V8.83871C9.63042 8.10064 10.2311 7.5 10.9691 7.5H26.1613C26.8994 7.5 27.5 8.10064 27.5 8.83871V20.4409C27.5 20.6876 27.3001 20.8871 27.0538 20.8871Z"
      fill="#414042"
    />
    <path
      d="M5.52906 24.457C5.42865 24.457 5.32825 24.4235 5.2448 24.3548C5.05471 24.1977 5.02838 23.9166 5.18546 23.7265L6.66205 21.9411C6.81958 21.7519 7.1007 21.7251 7.29036 21.8818C7.48045 22.0389 7.50678 22.32 7.34971 22.5101L5.87311 24.2955C5.78475 24.4017 5.65713 24.457 5.52906 24.457Z"
      fill="#414042"
    />
    <path
      d="M19.0309 22.6716H7.00566C6.75933 22.6716 6.55942 22.4721 6.55942 22.2254C6.55942 21.9786 6.75933 21.7791 7.00566 21.7791H19.0309C19.2772 21.7791 19.4771 21.5792 19.4771 21.3329V18.6559C19.4771 18.4092 19.6766 18.2097 19.9233 18.2097C20.1701 18.2097 20.3696 18.4092 20.3696 18.6559V21.3329C20.3696 22.071 19.7694 22.6716 19.0309 22.6716Z"
      fill="#414042"
    />
    <path
      d="M2.94624 24.4566C2.69991 24.4566 2.5 24.2571 2.5 24.0103V12.4082C2.5 11.6701 3.10064 11.0695 3.83871 11.0695H10.0963C10.3426 11.0695 10.5425 11.2694 10.5425 11.5157C10.5425 11.762 10.3426 11.9619 10.0963 11.9619H3.83871C3.59283 11.9619 3.39247 12.1623 3.39247 12.4082V24.0103C3.39247 24.2571 3.19256 24.4566 2.94624 24.4566Z"
      fill="#414042"
    />
    <path d="M20.4107 14.6394C20.9037 14.6394 21.3031 14.2401 21.3031 13.747C21.3031 13.2539 20.9037 12.8545 20.4107 12.8545C19.9176 12.8545 19.5182 13.2539 19.5182 13.747C19.5182 14.2401 19.9176 14.6394 20.4107 14.6394Z" />
    <path d="M23.9297 14.6399C24.4228 14.6399 24.8222 14.2405 24.8222 13.7474C24.8222 13.2543 24.4228 12.8549 23.9297 12.8549C23.4366 12.8549 23.0372 13.2543 23.0372 13.7474C23.0372 14.2401 23.4366 14.6399 23.9297 14.6399Z" />
    <path d="M13.22 14.6394C13.7131 14.6394 14.1125 14.2401 14.1125 13.747C14.1125 13.2539 13.7131 12.8545 13.22 12.8545C12.7269 12.8545 12.3275 13.2539 12.3275 13.747C12.3275 14.2401 12.7269 14.6394 13.22 14.6394Z" />
    <path d="M16.7899 14.6399C17.283 14.6399 17.6824 14.2405 17.6824 13.7474C17.6824 13.2543 17.283 12.8549 16.7899 12.8549C16.2968 12.8549 15.8974 13.2543 15.8974 13.7474C15.8974 14.2401 16.2968 14.6399 16.7899 14.6399Z" />
  </svg>
);

HaveAQuery.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default HaveAQuery;
