import { API_ENDPOINTS } from "../constants";
import { apisClient as api } from "./api";

export const fetchCountries = () =>
  api.get(`${API_ENDPOINTS.REFERENCE}/countries`);

export const fetchCurrencies = () =>
  api.get(`${API_ENDPOINTS.REFERENCE}/currencies`);

export const fetchServices = query =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-services`,
    query,
  });

export const fetchProducts = query =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-products`,
    query,
  });

export const fetchNetworks = () =>
  api.get({
    path: `${API_ENDPOINTS.REFERENCE}/prefs-networks`,
  });
