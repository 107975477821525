import get from "lodash/get";

import { INVOICE_TYPES } from "@dpdgroupuk/mydpd-enums";

import { F } from "../../../../../constants";

export const mapDataToForm = invoiceDefaults =>
  F.INVOICE_DEFAULTS.FIELDS.reduce((acc, field) => {
    if (
      field === F.INVOICE_DEFAULTS.INVOICE_SELECTION ||
      field === F.INVOICE_DEFAULTS.INVOICE_TYPE
    ) {
      acc[field] = get(
        invoiceDefaults,
        field,
        INVOICE_TYPES.SELECT_EACH_TIME_INV_TYPE
      );
    } else {
      acc[field] = get(invoiceDefaults, field, null);
    }

    return acc;
  }, {});
