import { includes, toNumber, toString } from "lodash";

import { F } from "../../../constants";

const WEIGHT_FIELDS = [
  F.SHIPPING_DEFAULTS.INT_WEIGHT,
  F.SHIPPING_DEFAULTS.DOM_WEIGHT,
  F.SHIPPING_DEFAULTS.DIM_WEIGHT,
];

export default (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR": {
      if (includes(WEIGHT_FIELDS, action.meta.field)) {
        return {
          ...state,
          values: {
            ...state.values,
            [action.meta.field]:
              action.payload && toString(toNumber(action.payload)),
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
