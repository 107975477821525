import { createSelector } from "reselect";

export const getData = state => state.app.salesforce;

export const isModalShown = createSelector(getData, ({ showModal = false }) => {
  return showModal;
});

export const isLoading = createSelector(getData, ({ isLoading = false }) => {
  return isLoading;
});
