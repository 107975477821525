export const WELCOME_FOOTER = {
  LOAD: "37Dwku6C48kIGDTz",
  ON_CLICK_TERMS_AND_CONDITIONS: "ylRgN16GAqACj8n1",
};

export const MENU = {
  LOAD: "4BI_Eu6C48kIGF1f",
  ON_CLICK_ADDRESS_BOOK: "7n3Hsu6C48kIGJT_",
  ON_CLICK_COLLECTIONS: "8jiVsu6C48kIGHV3",
  ON_CLICK_DELIVERIES: "odDVsu6C48kIGHXH",
  ON_CLICK_DEPOT_FINDER: "I8insu6C48kIGJWd",
  ON_CLICK_DPD_LOGO: "VaTXsu6C48kIGJZq",
  ON_CLICK_HELP: "N.0Xsu6C48kIGJYe",
  ON_CLICK_LOGOUT: "tUGXsu6C48kIGJZE",
  ON_CLICK_MYDPD: "WHglsu6C48kIGNV.",
  ON_CLICK_SEARCH: "JL3nsu6C48kIGJXr",
  ON_CLICK_SEARCH_DROPDOWN: "FYZ_su6C48kIGAgo",
  ON_CLICK_SHIPPING: "GKRlsu6C48kIGNaN",
  ON_CLICK_SHOP_RETURNS: "AChVsu6C48kIGHWd",
};

export const WELCOME_MENU = {
  LOAD: "Kd5Ycu6C48kIGGwc",
  ON_CLICK_API: "6dMMcu6C48kIGM2a",
  ON_CLICK_MY_ACCOUNT_DETAILS: "7zFUcu6C48kIGMwt",
  ON_CLICK_REPORTS: "k.i0cu6C48kIGM1L",
  ON_CLICK_WELCOME: "Obt4cu6C48kIGGyB",
};

export const IMPORTANT_NOTICE_MODAL = {
  INTERFACE_ID: "wS0Lwu6C48kIGI1e",
  LOAD: "AqQ7wu6C48kIGI9I",
  ON_OK: "OOwFEu6C48kIGD8K",
};

export const WELCOME_PAGE = {
  LOAD: "KXfjwu6C48kIGItY",
  INTERFACE_ID: "otlDwu6C48kIGIiJ",
};

export const QUICK_LINKS = {
  ON_CLICK_CREATE_SHIPMENT: "yLQN3l6GAqACgGq_",
  ON_CLICK_DELIVERY_DASHBOARD: "rP9pUV6GAqACgBmX",
  ON_CLICK_MANAGE_YOUR_SHIPMENTS: "EBbN3l6GAqACgGxa",
  ON_CLICK_MY_ACCOUNT: "hybd3l6GAqACgGzY",
  ON_CLICK_HAVE_A_QUERY: "TODO_ASK_DESIGN",
};

export const API_PAGE = {
  LOAD: "PWnqZD6GAqACwWpY",
  INTERFACE_ID: "WUwKZD6GAqACwWSu",
  ON_CLICK_HERE: "TDFuZD6GAqACwXVr",
};

export const SHIPPING_DEFAULTS_IDS = {
  INTERFACE_ID: "o2sA6HaFYFxqaIlS",
  CLICK_SHIPPING_DEFAULTS: "o2sA6HaFYFxqaIlS",
  CLICK_CHECKBOX_ALLOW_PARCELS_TO_BE_SHIPPED: "gCmi6HaFYFxqaLqx",
  CLICK_CHECKBOX_SENDERS_ADDRESS_ON_LABEL: "FIrS6HaFYFxqaL20",
  CLICK_DEFAULT_SHIPMENT_DATE: "TgEo6HaFYFxqaJzh",
  CLICK_DROPDOWN_LIST_ADDRESSBOOK_TYPE: "hu3Q6HaFYFxqaJvd",
  CLICK_DROPDOWN_LIST_COUNTRY: "ZUOQ6HaFYFxqaJks",
  CLICK_DROPDOWN_LIST_CURRENCY: "TRqk6HaFYFxqaKDJ",
  CLICK_DROPDOWN_LIST_EXTENDED_LIABILITY: "p3NE6HaFYFxqaKBw",
  CLICK_DROPDOWN_LIST_PRODUCT: "p9zA6HaFYFxqaJSX",
  CLICK_DROPDOWN_LIST_PRODUCT_SERVICE: "rd0E6HaFYFxqaKAc",
  CLICK_DROPDOWN_LIST_SERVICE: "TjzA6HaFYFxqaJSz",
  CLICK_RADIO_BUTTON_AUTO_PRINT: "p9Sy6HaFYFxqaMBs",
  CLICK_RADIO_BUTTON_TOTAL_NO_OF_PACKAGES: "MV6K6HaFYFxqaMPf",
  CLICK_SAVE: "AtzA6HaFYFxqaJRs",
  LOAD: "OlzA6HaFYFxqaJQs",
};

export const INVOICE_SETTINGS_IDS = {
  INTERFACE_ID: "T.zzP7aFYFxqYpLa",
  CLICK_INVOICE_SETTINGS: "T.zzP7aFYFxqYpLa",
  CLICK_DROPDOWN_LIST_INVOICE_SELECTION: "iR83P7aFYFxqYrBT",
  CLICK_DROPDOWN_LIST_INVOICE_TYPE: "1oWPP7aFYFxqYrD7",
  CLICK_SAVE: "De83P7aFYFxqYrA.",
  LOAD: "d683P7aFYFxqYrAV",
};

export const CONTACT_DETAILS_IDS = {
  INTERFACE_ID: "0EF.z7aFYFxqYoYH",
  CLICK_CONTACT_DETAILS: "0EF.z7aFYFxqYoYH",
  CLICK_DROPDOWN_LIST_TITLE: "oqSz77aFYFxqYnzm",
  CLICK_SAVE: "8QFAL7aFYFxqYsN5",
  LOAD: ".21tz7aFYFxqYqZN",
};

export const COLLECTION_DEFAULTS_IDS = {
  INTERFACE_ID: "YA5Jv7aFYFxqYtoV",
  CLICK_COLLECTION_DEFAULTS: "YA5Jv7aFYFxqYtoV",
  CLICK_DROPDOWN_LIST_PRODUCT: "Wg4zv7aFYFxqYgdM",
  CLICK_DROPDOWN_LIST_SERVICE: "HXjzv7aFYFxqYggX",
  CLICK_SAVE: "A_Yzv7aFYFxqYgcw",
  LOAD: "vXYzv7aFYFxqYgcC",
};

export const PRINTING_SETTINGS_IDS = {
  INTERFACE_ID: "1r.8WnaFYFxqrJQJ",
  CLICK_PRINTING_SETTINGS: "1r.8WnaFYFxqrJQJ",
  CLICK_BUTTON_CONFIGURE_USB_PRINTER: "Yrui.naFYFxqRihY",
  CLICK_CHECKBOX_ASSET_NUMBER: "cggU.naFYFxqRhT7",
  CLICK_CHECKBOX_PRINTING_TO_THERMAL_PRINTER: "BIdE.naFYFxqRgxn",
  CLICK_DROPDOWN_LIST_PRINTER_NAME: "Brws.naFYFxqRh2D",
  CLICK_DROPDOWN_LIST_PRINTER_TYPE: "nlSs.naFYFxqRh3i",
  CLICK_RADIO_BUTTON_LABELS_PER_PAGE: "H69S.naFYFxqRiuJ",
  CLICK_RADIO_BUTTON_DRIVER_TYPE: "unknown",
  CLICK_SAVE: "7fBe.naFYFxqRsSI",
  LOAD: "LPpLWnaFYFxqRtVy",
};

export const ECOMMERCE_IDS = {
  INTERFACE_ID: "s_fdjn6GAqAALIAv",
  CLICK_GET_STARTED: "M232Tn6GAqAALLII",
  LOAD: "PEKrjn6GAqAALKoA",
};
