import get from "lodash/get";

import {
  FIELDS,
  PRE_ARRANGED,
} from "../../../../../constants/forms/collectionDefaults";

export const mapDataToForm = collectionDefaults =>
  FIELDS.reduce((acc, field) => {
    acc[field] = get(
      collectionDefaults,
      field,
      field === PRE_ARRANGED ? false : ""
    );
    return acc;
  }, {});
