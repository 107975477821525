import get from "lodash/get";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { F } from "../../../../../constants";
import * as PrinterSettingsModel from "./model";

export const getPrintingSettingsValues = getFormValues(
  F.FORMS_NAMES.PRINTING_SETTING_FORM
);

export const isPrintToThermal = createSelector(
  getPrintingSettingsValues,
  formValues => get(formValues, F.PRINTING_SETTINGS.PRINT_TO_THERMAL, false)
);

export const isMissingAssetNumber = createSelector(
  getPrintingSettingsValues,
  formValues => get(formValues, F.PRINTING_SETTINGS.MISSING_ASSET_NUMBER, false)
);

export const getThermalPrinterName = createSelector(
  getPrintingSettingsValues,
  formValues => get(formValues, F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME, null)
);

export const getThermalPrinterDriverType = createSelector(
  getPrintingSettingsValues,
  formValues =>
    parseInt(
      get(formValues, F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE, null)
    )
);

export const getThermalPrinterType = createSelector(
  getPrintingSettingsValues,
  formValues =>
    get(
      formValues,
      F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE,
      F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPES[0].value
    )
);

export const getData = state => state.app.printingSettings;

const getPrinters = state => getData(state).printers;

export const isConfigurePrinterAvailable = createSelector(
  getPrinters,
  PrinterSettingsModel.isConfigurePrinterAvailable
);

export const isTestPrinterAvailable = createSelector(
  getThermalPrinterName,
  getPrinters,
  PrinterSettingsModel.isTestPrinterAvailable
);

export const getPrintersKeyValue = createSelector(getPrinters, printers =>
  printers.map(({ deviceName }) => deviceName)
);
