import { M } from "../../../../../constants";
import { ACTIONS } from "./actions";

export const initialState = {
  printers: [],
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_PRINTERS.SUCCESS:
    case ACTIONS.FETCH_PRINTERS.FAILURE:
      return {
        ...state,
        printers: action.payload.data || [],
      };
    case ACTIONS.TEST_PRINTER.FAILURE:
    case ACTIONS.CONFIGURE_PRINTER.FAILURE:
      return {
        ...state,
        message: M.PRINTER_IS_UNAVAILABLE,
      };
    case ACTIONS.CONFIGURE_PRINTER.SUCCESS: {
      const { description, name } = action.payload;
      return {
        ...state,
        message:
          (name && `${M.INSTALLED_PRINTER}: ${description || name}`) ||
          M.PRINTER_INSTALLED_SUCCESSFULLY,
      };
    }
    case ACTIONS.TEST_PRINTER.SUCCESS:
      return {
        ...state,
        message: M.PRINTER_REQUEST_SENT,
      };
    case ACTIONS.RESET:
      return initialState;
    default:
      return state;
  }
};
