import {
  createAction,
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { salesforceApis } from "../../apis";

const ACTION_NAMESPACE = "salesforce";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  START_FETCH_SUPPORT_URL: createAsyncActionTypes("START_FETCH_SUPPORT_URL"),
  FETCH_SUPPORT_URL: createAsyncActionTypes("FETCH_SUPPORT_URL"),
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
});

export const generateAuthToken = createAsyncAction(
  salesforceApis.generateAuthToken,
  ACTIONS.FETCH_SUPPORT_URL
);

export const startGenerateToken = createAsyncAction(
  () => async dispatch => {
    try {
      const res = await dispatch(generateAuthToken());
      const { redirectUri } = res.data;
      window.open(redirectUri, "_blank");
    } catch (err) {
      return dispatch(showModal);
    }
  },
  ACTIONS.START_FETCH_SUPPORT_URL
);

export const showModal = createAction(ACTIONS.SHOW_MODAL);

export const hideModal = createAction(ACTIONS.HIDE_MODAL);
